import {Api} from './apiDefinition.js'

export const getCampaignReport = async ({params}) => {
  const {data} = await Api.get('/campaigns/report', {params, responseType: 'blob'})
  return data
}
export const getCampaigns = async ({params}) => {
  const {data} = await Api.get('/campaigns', {params})
  return data
}
export const postCampaign = async ({payload}) => {
  const {data} = await Api.post('/campaigns', payload)
  return data
}
export const patchCampaign = async ({params, payload}) => {
  const {data} = await Api.patch('/campaigns', payload, {params})
  return data
}
export const deleteCampaign = async ({params}) => {
  await Api.delete('/campaigns', {params})
}
export const postCampaignEmailsSend = async ({params, payload}) => {
  const {data} = await Api.post('/campaigns/emails/send', payload, {params})
  return data
}
export const postCampaignsOutreachTrackerCompaniesSearch = async ({
  params,
  payload
}) => {
  const {data} = await Api.post(
    '/campaigns/outreach-tracker/companies/search',
    payload,
    {
      params
    }
  )
  return data
}
export const postCampaignsOutreachTrackerPeopleSearch = async ({params, payload}) => {
  const {data} = await Api.post('/campaigns/outreach-tracker/people/search', payload, {
    params
  })
  return data
}
export const getCampaignMetaData = async ({params}) => {
  const {data} = await Api.get('/campaigns/outreach-tracker/meta-data', {
    params
  })
  return data
}
