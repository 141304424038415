import {useRef} from 'react'
import {Form, InputGroup, ListGroup} from 'react-bootstrap'
import {Button} from '../atoms/Button.js'

import {useAutosizeTextArea} from '../../utils/hooks.js'
import {InputWrapper} from '../../utils/hooks.js'

const InputField = ({
  index,
  value,
  error,
  handlePreferenceChange,
  handleBlur,
  handleRemovePreference,
  moveToNext
}) => {
  const inputRef = useRef(null)
  useAutosizeTextArea(inputRef, value)

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      moveToNext(index)
    }
  }

  return (
    <InputWrapper
      tooltipMessage={error}
      tooltipId={`tooltip-preference-${index}`}
    >
      <InputGroup>
        <Form.Control
          as='textarea'
          id={`preference-${index}`}
          placeholder='Enter your message preferences'
          rows={1}
          ref={inputRef}
          value={value}
          onChange={(e) => handlePreferenceChange(index, e.target.value)}
          onBlur={() => handleBlur(index)}
          onKeyDown={onKeyDown}
          className={!!error ? 'invalidInput' : ''}
          style={{overflow: 'hidden', resize: 'none'}}
        />
        <Button
          variant='outline-primary'
          onClick={() => handleRemovePreference(index)}
          className={!!error ? 'invalidInput inputFieldButton' : 'inputFieldButton'}
          children='-'
        />
      </InputGroup>
    </InputWrapper>
  )
}

export const DynamicInputGroup = ({values, onChange, inputValidator}) => {
  const handleAddPreference = () => {
    if (!values.length || values[values.length - 1].trim() !== '') {
      onChange([...values, ''])
    }
  }

  const handleRemovePreference = (index) => {
    onChange(values.filter((_, i) => i !== index))
  }

  const handlePreferenceChange = (index, value) => {
    const newPreferences = [...values]
    newPreferences[index] = value
    onChange(newPreferences)
  }

  const handleBlur = (index) => {
    if (values[index].trim() === '' && values.length > 1) {
      handleRemovePreference(index)
    }
  }
  const moveToNext = (currentIndex) => {
    const nextIndex = currentIndex + 1
    if (nextIndex < values.length) {
      const nextInput = document.getElementById(`preference-${nextIndex}`)
      if (nextInput) {
        nextInput.focus()
      }
    } else {
      handleAddPreference()
      setTimeout(() => {
        const nextInput = document.getElementById(`preference-${nextIndex}`)
        if (nextInput) {
          nextInput.focus()
        }
      }, 20)
    }
  }

  const moveToLast = () => {
    handleAddPreference()
    setTimeout(() => {
      const lastInput = document.getElementById(`preference-${values.length}`)
      if (lastInput) {
        lastInput.focus()
      }
    }, 20)
  }

  return (
    <ListGroup variant='flush'>
      {values.map((value, index) => (
        <ListGroup.Item
          key={index}
          style={{paddingLeft: 0, paddingRight: 0}}
        >
          <InputField
            index={index}
            value={value}
            error={inputValidator(value, 'Preference')}
            handlePreferenceChange={handlePreferenceChange}
            handleBlur={handleBlur}
            handleAddPreference={handleAddPreference}
            handleRemovePreference={handleRemovePreference}
            moveToNext={moveToNext}
          />
        </ListGroup.Item>
      ))}
      <ListGroup.Item style={{alignSelf: 'center'}}>
        <span
          className={
            'material-symbols-outlined color--accent clickable transition-fast t-scale--small'
          }
          onClick={moveToLast}
        >
          {'add_circle'}
        </span>
      </ListGroup.Item>
    </ListGroup>
  )
}
