import {toast} from 'react-hot-toast'

export const errorToast = (message) => {
  toast.error(
    (t) => (
      <span className='d-flex align-items-center'>
        {message}
        <span
          className='material-symbols-outlined color--s-dark clickable transition-medium t-color--accent'
          onClick={() => toast.dismiss(t.id)}
        >
          close
        </span>
      </span>
    ),
    {
      duration: 60000
    }
  )
}

export const successToast = (message, duration = 10000) => {
  toast.success(<span className='d-flex align-items-center'>{message}</span>, {
    duration: duration
  })
}
