import {useState, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useQueryClient} from '@tanstack/react-query'

import {Paper} from '@mui/material'
import {TabPanel, TabContext} from '@mui/lab'
import {Dropdown} from 'react-bootstrap'
import {Spinner} from '../atoms/Spinner.js'
import {EditableField} from '../molecules/EditableField.js'
import {DynamicTabList} from '../organisms/DynamicTabList.js'
import {StatusTab, SettingsTab, InsightsTab} from '../organisms/CampaignInsightsTabs.js'
import {AudienceModal} from '../organisms/AudienceModals.js'
import {DeleteCampaignModal} from '../organisms/CampaignModals.js'
import {SidebarTemplate} from '../templates/SidebarTemplate.js'

import {
  useAudienceMetaData,
  usePersonSearchSettings,
  useCompanySearchSettings
} from '../../hooks/client/useAudiencesClientState.js'
import {useCampaignAtom} from '../../hooks/client/useCampaignsClientState.js'

import {
  useGetCampaigns,
  usePatchCampaign,
  useGetCampaignReport,
  useDeleteCampaign,
  useGetCampaignMetaData
} from '../../hooks/server/useCampaignsServerState.js'
import {
  useGetAudiences,
  usePatchAudience
} from '../../hooks/server/useAudiencesServerState.js'
import {
  useGetMessageInstructions,
  usePatchMessageInstructions
} from '../../hooks/server/useAgentServerState.js'

import {isTruthy} from '../../utils/hooks.js'

export const CampaignInsights = () => {
  // GLOBAL UI STATE
  const {audienceMetaDataFromResponse} = useAudienceMetaData()
  const {personSearchSettingsFromResponse} = usePersonSearchSettings()
  const {companySearchSettingsFromResponse} = useCompanySearchSettings()
  const {campaignAtomFromResponse, campaignStepsToResponse} = useCampaignAtom()

  //LOCAL UI STATE
  const location = useLocation()
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState('0')
  const [viewDescription, setViewDescription] = useState(false)

  const [campaignServerState, setCampaignServerState] = useState(null)
  const [campaign, setCampaign] = useState(null)
  const [audienceMetaData, setAudienceMetaData] = useState(null)
  const [personSearchSettings, setPersonSearchSettings] = useState(null)
  const [companySearchSettings, setCompanySearchSettings] = useState(null)

  const [showAudienceModal, setShowAudienceModal] = useState(false)
  const [showDeleteCampaignModal, setShowDeleteCampaignModal] = useState(false)

  //QUERY SERVER STATES
  const {data: campaignMetaData, fetchStatus: campaignMetaDataFetchStatus} =
    useGetCampaignMetaData({
      enabled: isTruthy(campaign?.campaignId),
      params: {campaign_id: campaign?.campaignId}
    })

  const {data: campaigns, fetchStatus: campaignsFetchStatus} = useGetCampaigns({
    params: {per_page: 10000, page: 0}
  })
  const {data: audiences, fetchStatus: audiencesFetchStatus} = useGetAudiences()
  const {data: messageInstructions, fetchStatus: messageInstructionsFetchStatus} =
    useGetMessageInstructions()

  const {refetch: refetchCampaignReport} = useGetCampaignReport({
    params: {campaign_id: campaign?.campaignId}
  })

  //MUTATE SERVER STATES
  const queryClient = useQueryClient()
  const {mutate: patchCampaign, status: patchCampaignStatus} = usePatchCampaign()
  const {mutateAsync: deleteCampaign, status: deleteCampaignStatus} =
    useDeleteCampaign()
  const {mutateAsync: patchAudience, status: patchAudienceStatus} = usePatchAudience({
    showToast: false
  })
  const {
    mutateAsync: patchMessageInstructions,
    status: patchMessageInstructionsStatus
  } = usePatchMessageInstructions({
    showToast: false,
    invalidateQuery: false
  })

  //VARIABLES
  const tabsData = [
    {
      value: '0',
      iconName: 'progress_activity',
      tabName: 'Status'
    },
    {
      value: '1',
      iconName: 'settings',
      tabName: 'Settings'
    },
    {
      value: '2',
      iconName: 'monitoring',
      tabName: 'Insights'
    }
  ]

  const campaignIsResumable =
    ['stopped', 'paused', 'needs_review'].includes(campaign?.campaignStatus) &&
    [
      'failed_due_to_changed_email_account',
      'failed_retrieving_connected_email_account',
      'failed_due_to_insufficient_credits',
      'failed_retrieving_valid_access_token',
      null
    ].includes(campaign?.campaignReviewReason)

  const campaignIsUpdating =
    campaignsFetchStatus === 'fetching' ||
    audiencesFetchStatus === 'fetching' ||
    patchCampaignStatus === 'pending' ||
    deleteCampaignStatus === 'pending' ||
    patchAudienceStatus === 'pending' ||
    patchMessageInstructionsStatus === 'pending' ||
    campaignMetaDataFetchStatus === 'fetching'

  //HANDLERS
  const handleDeleteCampaign = async () => {
    const miInUse = new Set()
    campaigns.items.forEach((c) => {
      if (c.campaign_id !== campaign?.campaignId) {
        c.steps.forEach((step) => {
          miInUse.add(step.messageInstructionsId)
        })
      }
    })
    let agentParams = []
    campaign?.steps.forEach((step) => {
      if (!miInUse.has(step.messageInstructionsId)) {
        agentParams.push({message_instructions_id: step.messageInstructionsId})
      }
    })

    navigate('/dashboard')
    await deleteCampaign({params: {campaign_id: campaign?.campaignId}})
    const tasks = [
      patchAudience({
        params: {audience_id: campaign?.audienceId},
        payload: {used_in_campaign: false}
      })
    ].concat(
      agentParams.map((params) =>
        patchMessageInstructions({params, payload: {used_in_campaign: false}})
      )
    )
    await Promise.all(tasks)
    queryClient.invalidateQueries({queryKey: ['messageInstructions']})
  }

  const handleReportDownload = () => {
    refetchCampaignReport().then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {type: 'text/csv'})
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${campaign?.campaignName} - report`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
  }

  const setCampaignKey = (key, value) => {
    setCampaign((prevCampaign) => ({
      ...prevCampaign,
      [key]: value
    }))
  }

  //LISTENERS
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const campaignId = urlParams.get('campaign_id')
    const campaign = campaigns?.items?.find((c) => c?.campaign_id === campaignId)
    const audience = audiences?.find((a) => a?.audience_id === campaign?.audience_id)
    if (isTruthy(campaign) && isTruthy(audience)) {
      setCampaignServerState(campaign)
      setCampaign(campaignAtomFromResponse(campaign))
      setAudienceMetaData(audienceMetaDataFromResponse(audience))
      setPersonSearchSettings(personSearchSettingsFromResponse(audience))
      setCompanySearchSettings(companySearchSettingsFromResponse(audience))
    }
  }, [location.search, audiences, campaigns])

  return (
    <>
      <DeleteCampaignModal
        show={showDeleteCampaignModal}
        onHide={() => setShowDeleteCampaignModal(false)}
        onDelete={handleDeleteCampaign}
        campaignId={campaign?.campaignId}
      />
      {isTruthy(audienceMetaData) &&
        isTruthy(personSearchSettings) &&
        isTruthy(companySearchSettings) && (
          <AudienceModal
            show={showAudienceModal}
            onHide={() => {
              setShowAudienceModal(false)
            }}
            audienceMetaDataAtom={audienceMetaData}
            personSearchSettingsAtom={personSearchSettings}
            companySearchSettingsAtom={companySearchSettings}
          />
        )}
      <SidebarTemplate>
        <TabContext value={activeTab}>
          <Paper
            className='d-flex flex-column align-items-center background--p-light'
            variant='outlined'
            style={{
              padding: '0 0.55rem 0 0.55rem',
              borderRadius: '0 0 1rem 1rem',
              width: 'fit-content',
              alignSelf: 'center'
            }}
          >
            <DynamicTabList
              tabs={tabsData}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </Paper>

          <Paper
            className='d-flex flex-column align-items-stretch background--p-light'
            variant='outlined'
            style={{padding: '1rem', borderRadius: '1rem', margin: '2rem'}}
          >
            <div className='d-flex justify-content-between'>
              <div className='d-flex flex-column align-items-start justify-content-start'>
                <h5 className='d-flex align-items-center'>
                  {campaignIsUpdating ? (
                    <Spinner style={{margin: '0 0.75rem 0 0.25rem'}} />
                  ) : (
                    <span
                      className='material-symbols-outlined'
                      style={{marginRight: '0.25rem', fontSize: '1.728rem'}}
                    >
                      campaign
                    </span>
                  )}
                  <EditableField
                    value={campaign?.campaignName}
                    onChange={(val) => setCampaignKey('campaignName', val)}
                    onBlur={() => {
                      if (
                        campaign?.campaignName !== campaignServerState?.campaign_name
                      ) {
                        patchCampaign({
                          params: {campaign_id: campaign?.campaignId},
                          payload: {campaign_name: campaign?.campaignName}
                        })
                      }
                    }}
                  />
                </h5>
                <p className='d-flex'>
                  {campaign?.campaignDescription?.length > 40 && (
                    <span
                      className='material-symbols-outlined clickable transitions-fast t-color--accent '
                      style={{
                        marginRight: '0.25rem',
                        fontSize: '1.44rem'
                      }}
                      onClick={() => setViewDescription(!viewDescription)}
                    >
                      {viewDescription ? 'unfold_less' : 'unfold_more'}
                    </span>
                  )}
                  <EditableField
                    value={campaign?.campaignDescription}
                    onChange={(val) => setCampaignKey('campaignDescription', val)}
                    style={{
                      maxWidth: '24rem',
                      maxHeight: viewDescription ? '8rem' : '2rem',
                      overflow: 'hidden',
                      overflowY: viewDescription ? 'auto' : 'hidden',
                      whiteSpace: viewDescription ? 'normal' : 'nowrap',
                      textOverflow: 'ellipsis',
                      transition: 'max-height 0.5s ease-in-out'
                    }}
                    inputStyle={{width: '30rem'}}
                    onBlur={() => {
                      if (
                        campaign?.campaignDescription !==
                        campaignServerState?.campaign_description
                      ) {
                        patchCampaign({
                          params: {campaign_id: campaign?.campaignId},
                          payload: {
                            campaign_description: campaign?.campaignDescription
                          }
                        })
                      }
                    }}
                  />
                </p>
              </div>

              <Dropdown drop='start'>
                <Dropdown.Toggle
                  variant='none'
                  bsPrefix='dropdownToggle'
                  style={{border: 'none', padding: 0}}
                  children={
                    <span
                      className='material-symbols-outlined transition-fast clickable t-scale--small t-color--accent'
                      style={{fontSize: '2rem', color: 'black'}}
                    >
                      {'more_horiz'}
                    </span>
                  }
                />
                <Dropdown.Menu>
                  <Dropdown.Item
                    className='d-flex align-items-center'
                    onClick={() =>
                      patchCampaign({
                        params: {campaign_id: campaign?.campaignId},
                        payload: {
                          campaign_status: campaignIsResumable
                            ? 'in_progress'
                            : 'paused',
                          campaign_review_reason: campaignIsResumable
                            ? null
                            : campaign?.campaignReviewReason
                        }
                      })
                    }
                  >
                    <span
                      className={'material-symbols-outlined'}
                      style={{marginRight: '0.25rem'}}
                    >
                      {campaignIsResumable ? 'play_arrow' : 'pause'}
                    </span>
                    {campaignIsResumable ? 'Resume Campaign' : 'Pause Campaign'}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='d-flex align-items-center'
                    onClick={() => setShowDeleteCampaignModal(true)}
                  >
                    <span
                      className={'material-symbols-outlined'}
                      style={{marginRight: '0.25rem'}}
                    >
                      {'delete'}
                    </span>
                    Delete Campaign
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='d-flex align-items-center'
                    onClick={() => handleReportDownload()}
                  >
                    <span
                      className={'material-symbols-outlined'}
                      style={{marginRight: '0.25rem'}}
                    >
                      {'download'}
                    </span>
                    Download Report
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <hr style={{marginTop: 0}} />

            <TabPanel
              value={'0'}
              style={{margin: 0, padding: 0, maxHeight: '70vh', overflowY: 'auto'}}
            >
              <StatusTab
                campaign={campaign}
                campaignMetaData={campaignMetaData}
              />
            </TabPanel>

            <TabPanel
              value={'1'}
              style={{margin: 0, padding: 0, maxHeight: '70vh', overflowY: 'auto'}}
            >
              <SettingsTab
                campaign={campaign}
                setCampaignKey={setCampaignKey}
                campaignServerState={campaignServerState}
                audienceMetaData={audienceMetaData}
                audienceOnClick={() => {
                  setShowAudienceModal(true)
                }}
                onInputInstructionsUpdate={() => {
                  patchCampaign({
                    params: {campaign_id: campaign?.campaignId},
                    payload: {
                      campaign_sorting_instructions:
                        campaign?.campaignSortingInstructions
                    }
                  })
                }}
                onOutreachUpdate={() => {
                  patchCampaign({
                    params: {campaign_id: campaign?.campaignId},
                    payload: {
                      max_people_outreach: campaign?.maxOutreachMessages,
                      max_people_outreach_per_day: campaign?.maxOutreachMessagesPerDay,
                      max_people_outreach_per_company:
                        campaign?.maxOutreachPeoplePerCompany,
                      steps: campaignStepsToResponse(campaign?.steps)
                    }
                  })
                }}
                campaignIsUpdating={campaignIsUpdating}
                messageInstructions={messageInstructions}
                messageInstructionsFetchStatus={messageInstructionsFetchStatus}
              />
            </TabPanel>
            <TabPanel
              value={'2'}
              style={{margin: 0, padding: 0, maxHeight: '70vh', overflowY: 'auto'}}
            >
              <InsightsTab campaign={campaign} />
            </TabPanel>
          </Paper>
        </TabContext>
      </SidebarTemplate>
    </>
  )
}
