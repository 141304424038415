import {atom} from 'recoil'
import {sessionStorageEffect} from './effects.js'

//ATOMS
export const campaignBuilderAtom = atom({
  key: 'campaignBuilder',
  default: {
    campaignName: '',
    campaignDescription: '',
    campaignSortingInstructions: '',
    maxOutreachMessages: undefined,
    maxOutreachMessagesPerDay: undefined,
    maxOutreachPeoplePerCompany: undefined,
    steps: [{index: 0, messageInstructionsId: '', waitdays: undefined}],
    peopleInAudience: undefined,
    companiesInAudience: undefined,
    audienceId: '',
    campaignId: '',
    campaignReviewReason: null,
    campaignStatus: '',
    companiesFetched: undefined,
    createdAt: '',
    messagesGenerated: undefined,
    peopleFetched: undefined,
    statistics: {
      outreachTrackingMetaData: {
        companyStatusAggregates: {
          awaitingOutreach: undefined,
          awaitingPeople: undefined,
          receivedBounce: undefined,
          receivedNoResponse: undefined,
          receivedResponseFromRecipient: undefined,
          sent: undefined,
          skipped: undefined
        },
        personOutreachAggregates: [
          {
            countPeople: undefined,
            messagesSent: undefined,
            skipReason: '',
            status: ''
          }
        ],
        personStatusAggregates: {
          awaitingMessage: undefined,
          awaitingOutreach: undefined,
          receivedBounce: undefined,
          receivedNoResponse: undefined,
          receivedResponseFromRecipient: undefined,
          sent: undefined,
          skipped: undefined
        }
      }
    },
    updatedAt: '',
    userId: ''
  },
  effects: [sessionStorageEffect('campaignBuilderInput')]
})
