import {Container, Col, Row} from 'react-bootstrap'
import {Paper} from '@mui/material'
import {InfoPageTemplate} from '../../templates/InfoPageTemplate.js'

export const Contact = () => {
  const contentColClass = 'd-flex flex-column justify-content-center align-items-center'
  const contentColBreakpoints = {
    xxl: 8,
    xl: 10,
    md: 11,
    xs: 12
  }

  return (
    <InfoPageTemplate>
      <Container
        id='contact-us'
        fluid
        className='background--p-light d-flex justify-content-center align-items-center'
        style={{paddingTop: '13rem', paddingBottom: '13rem'}}
      >
        <Col
          {...contentColBreakpoints}
          className={contentColClass}
        >
          <h1 className='d-text__font--heading text__placement--center color--p-dark d-flex align-items-center'>
            Contact Us
          </h1>
          <h3
            style={{padding: '1rem', textAlign: 'center'}}
            className='d-text__font--subheading color--s-dark'
          >
            We'd love to hear from you! Whether you have a question about our product,
            need assistance, or just want to talk about how we can help your sales team
            thrive, we're here for you.
          </h3>
          <Row style={{padding: '2rem', width: '100%'}}>
            <Col
              xxl={6}
              lg={7}
              md={8}
              sm={8}
              xs={12}
            >
              <h4
                style={{padding: '1rem'}}
                className='d-text__font--subheading color--s-dark d-flex align-items-center'
              >
                <span
                  className={'material-symbols-outlined color--accent'}
                  style={{marginRight: '0.5rem'}}
                >
                  {'handshake'}
                </span>
                Sales Inquiries
              </h4>
              <p className='d-text__font--body color--p-dark'>
                Looking to supercharge your sales process with Pitchr? Reach out to our
                sales team to see how we can tailor our solutions to fit your needs.
              </p>
              <h4
                style={{padding: '1rem'}}
                className='d-text__font--subheading color--s-dark d-flex align-items-center'
              >
                <span
                  className={'material-symbols-outlined color--accent'}
                  style={{marginRight: '0.5rem'}}
                >
                  {'contact_support'}
                </span>
                Customer Support
              </h4>
              <p className='d-text__font--body color--p-dark'>
                Already a user? Need help with something? Our support team is ready to
                assist you with any issues or questions you may have.
              </p>
              <h4
                style={{padding: '1rem'}}
                className='d-text__font--subheading color--s-dark d-flex align-items-center'
              >
                <span
                  className={'material-symbols-outlined color--accent'}
                  style={{marginRight: '0.5rem'}}
                >
                  {'info'}
                </span>
                General Inquiries
              </h4>
              <p className='d-text__font--body color--p-dark'>
                For all other questions, comments, or if you just want to chat, feel
                free to drop us a line. We’re always happy to connect.
              </p>
            </Col>
            <Col className='d-flex flex-column justify-content-center align-items-center'>
              <Paper
                className='background--s-light d-flex flex-column justify-content-center align-items-center'
                elevation={1}
                style={{padding: '2rem', width: '80%', height: 'auto', margin: '2rem'}}
              >
                <h3
                  style={{padding: '1rem'}}
                  className='d-text__font--subheading d-text__placement--center color--p-dark'
                >
                  Get in Touch
                </h3>
                <div className='d-flex flex-column justify-content-start align-items-start'>
                  <p className='d-text__font--body color--p-dark d-flex align-items-center'>
                    <span
                      className={'material-symbols-outlined color--accent clickable'}
                      style={{marginRight: '0.5rem'}}
                      onClick={() =>
                        (window.location.href = 'mailto:contact@pitchr.co')
                      }
                    >
                      {'mail'}
                    </span>
                    contact@pitchr.co
                  </p>
                  <p className='d-text__font--body color--p-dark d-flex align-items-center'>
                    <span
                      className={'material-symbols-outlined color--accent'}
                      style={{marginRight: '0.5rem'}}
                    >
                      {'call'}
                    </span>
                    +31647747164
                  </p>
                </div>
              </Paper>
            </Col>
          </Row>
        </Col>
      </Container>
    </InfoPageTemplate>
  )
}
