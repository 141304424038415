import {Api} from './apiDefinition.js'

//USER ID
export const getUserIdentity = async ({params}) => {
  const {data} = await Api.get('/user/identity', {params})
  return data
}

//USER CREDIT
export const getUserCredit = async () => {
  const {data} = await Api.get('/user/credit')
  return data
}

//USER PROFILE
export const getUserProfile = async () => {
  const {data} = await Api.get('/user/profile')
  return data
}
export const patchUserProfile = async ({payload}) => {
  const {data} = await Api.patch('/user/profile', payload)
  return data
}

//USER PERMISSIONS
export const getUserAccessToken = async ({params}) => {
  const {data} = await Api.get('/user/access-token', {params})
  return data
}
export const getUserThirdPartyProvisions = async () => {
  const {data} = await Api.get('/user/third-party-provisions')
  return data
}
export const putUserThirdPartyProvisions = async ({payload}) => {
  const {data} = await Api.put('/user/third-party-provisions', payload)
  return data
}
