import {useState} from 'react'
import {
  Stack,
  Badge,
  Row,
  Col,
  Container,
  ListGroup,
  Image,
  Collapse
} from 'react-bootstrap'
import {ReactComponent as LinkedinIcon} from '../atoms/assets/Linkedin.svg'
import {ReactComponent as FacebookIcon} from '../atoms/assets/Facebook.svg'
import {ReactComponent as GithubIcon} from '../atoms/assets/Github.svg'
import {ReactComponent as InstagramIcon} from '../atoms/assets/Instagram.svg'
import {ReactComponent as PinterestIcon} from '../atoms/assets/Pinterest.svg'
import {ReactComponent as TwitterXIcon} from '../atoms/assets/TwitterX.svg'
import {ReactComponent as YoutubeIcon} from '../atoms/assets/Youtube.svg'

import {isTruthy} from '../../utils/hooks.js'
import {MatchesFoundRounder} from '../../utils/calculations.js'
import {getCampaignStatusDetails} from './CampaignInsightsTabs.js'
import {formatUnderscoreString} from '../../utils/helpers.js'

const InfoRow = ({icon, text, condensed = false}) => (
  <p
    className='d-flex align-items-center color--s-dark'
    style={{padding: condensed ? '0' : '0.25rem 0rem 0.25rem 0rem', margin: 0}}
  >
    <span
      className='material-symbols-outlined'
      style={{fontSize: '1.44rem', marginRight: '0.25rem'}}
    >
      {icon}
    </span>
    <span
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
      title={text}
    >
      {text}
    </span>
  </p>
)

const socialIconMap = {
  linkedin: {
    icon: LinkedinIcon,
    color: '#0A66C2',
    label: 'LinkedIn Profile'
  },
  facebook: {
    icon: FacebookIcon,
    color: '#1877F2',
    label: 'Facebook Page'
  },
  github: {
    icon: GithubIcon,
    color: '#333333',
    label: 'GitHub Profile'
  },
  instagram: {
    icon: InstagramIcon,
    color: '#E4405F',
    label: 'Instagram Profile'
  },
  pinterest: {
    icon: PinterestIcon,
    color: '#E60023',
    label: 'Pinterest Page'
  },
  twitterx: {
    icon: TwitterXIcon,
    color: '#1DA1F2',
    label: 'Twitter Profile'
  },
  youtube: {
    icon: YoutubeIcon,
    color: '#FF0000',
    label: 'YouTube Channel'
  }
}

const getImage = (logo, backup) => {
  return logo ? (
    <Image
      fluid
      src={logo}
      onError={(e) => {
        e.target.onerror = null
        e.target.style.display = 'none'
      }}
      alt={backup}
      style={{
        maxWidth: '3.5rem',
        maxHeight: '3.5rem',
        padding: 0,
        margin: 0,
        borderRadius: '0.5rem'
      }}
    />
  ) : (
    <span
      className='material-symbols-outlined color--s-dark'
      style={{
        fontSize: '3.5rem',
        padding: 0,
        margin: 0,
        borderRadius: '0.5rem'
      }}
    >
      {backup}
    </span>
  )
}

const recordHeader = (header, condensed = false) => {
  return (
    <h6
      className='color--p-dark'
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: condensed ? 0 : ''
      }}
      title={header}
    >
      {header}
    </h6>
  )
}

const recordLink = (link, icon) => {
  return (
    <a
      href={link}
      target='_blank'
      rel='noopener noreferrer'
      className='d-flex align-items-center color--s-dark'
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '0.833rem',
        lineHeight: '0.833rem',
        width: 'fit-content'
      }}
      title={link}
    >
      {icon === 'linkedin' ? (
        <LinkedinIcon
          style={{
            width: '1.15rem',
            height: '1.15rem',
            borderRadius: '0.25rem',
            marginRight: '0.5rem',
            fill: '#0A66C2'
          }}
        />
      ) : (
        <span
          className='material-symbols-outlined'
          style={{marginRight: '0.25rem', fontSize: '1.2rem'}}
        >
          language
        </span>
      )}
      {link.replace(/^(https?:\/\/)?(www\.)?/, '')}
    </a>
  )
}

const recordDescription = (description) => {
  return (
    <span
      title={description}
      style={{
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 6,
        lineClamp: 6,
        overflow: 'hidden',
        whiteSpace: 'balance',
        textOverflow: 'ellipsis',
        padding: '0.5rem 0 0 0',
        margin: 0,
        textAlign: 'start'
      }}
    >
      {description}
    </span>
  )
}

const itemsRow = (iconName, items) => {
  return (
    <div className='d-flex align-items-center'>
      <span
        className='material-symbols-outlined'
        style={{fontSize: '1.44rem', marginRight: '0.25rem'}}
      >
        {iconName}
      </span>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          maxHeight: '4.5rem',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        {items?.map((i, index) => (
          <Badge
            key={index}
            style={{
              whiteSpace: 'wrap',
              margin: '0.1rem 0.1rem 0.1rem 0rem'
            }}
          >
            {i}
          </Badge>
        ))}
      </div>
    </div>
  )
}

// results:
// [
// 	{
// 		name: str
// 		description: str
// 		website: str
// 		revenue: str
// 		employees: str
// 		country: str
// 		industry: str
// 		logo: str
// 		technologies: [str]
// 		yearFounded: str
// 	}
// ]
export const CompanySearchResultsContainer = ({results}) => {
  const [openItems, setOpenItems] = useState({})
  const toggleItem = (id) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }

  return (
    <Container fluid>
      <ListGroup variant='flush'>
        {results.map((result, index) => (
          <ListGroup.Item
            key={index}
            style={{padding: '0.25rem 0.75rem 0.25rem 0.75rem'}}
          >
            <Row
              onClick={() => toggleItem(index)}
              className='clickable transition-fast t-background-color--s-light'
            >
              <Col
                xs={1}
                className='d-flex align-items-center justify-content-start'
              >
                {getImage(result?.logo, 'source_environment')}
              </Col>
              <Col
                xs={4}
                className='d-flex flex-column justify-content-center'
              >
                {isTruthy(result?.name) && recordHeader(result.name)}
                {isTruthy(result?.website) && recordLink(result.website)}
              </Col>
              <Col
                xs={3}
                className='d-flex flex-column justify-content-around'
              >
                {isTruthy(result?.employees) && (
                  <InfoRow
                    icon='group'
                    text={result.employees}
                  />
                )}
                {isTruthy(result?.revenue) && (
                  <InfoRow
                    icon='attach_money'
                    text={result.revenue}
                  />
                )}
              </Col>
              <Col
                xs={3}
                className='d-flex flex-column justify-content-around'
              >
                {isTruthy(result?.industry) && (
                  <InfoRow
                    icon='factory'
                    text={result.industry}
                  />
                )}
                {isTruthy(result?.country) && (
                  <InfoRow
                    icon='globe'
                    text={result.country}
                  />
                )}
              </Col>
            </Row>
            <Collapse
              in={openItems[index]}
              mountOnEnter
              unmountOnExit
            >
              <div id={`details-${index}`}>
                <Row>
                  <Col xs={8}>
                    {isTruthy(result?.description) &&
                      recordDescription(result.description)}
                    <div style={{padding: '0.5rem 0rem 0.5rem 0rem'}}>
                      {Object.keys(socialIconMap).map((socialKey) => {
                        const social = socialIconMap[socialKey]
                        const SocialIconComponent = social.icon
                        const socialUrl = result[socialKey]

                        if (socialUrl) {
                          return (
                            <a
                              key={socialKey}
                              href={socialUrl}
                              target='_blank'
                              rel='noopener noreferrer'
                              style={{display: 'inline-block', marginRight: '0.5rem'}}
                              aria-label={social.label}
                              title={socialUrl}
                            >
                              <SocialIconComponent
                                style={{
                                  width: '1.25rem',
                                  height: '1.25rem',
                                  fill: social.color
                                }}
                              />
                            </a>
                          )
                        }
                        return null
                      })}
                    </div>
                  </Col>
                  <Col xs={3}>
                    {isTruthy(result?.yearFounded) && (
                      <InfoRow
                        icon='calendar_today'
                        text={result.yearFounded}
                      />
                    )}
                    {isTruthy(result?.technologies) && (
                      <div className='d-flex align-items-center'>
                        {itemsRow('biotech', result?.technologies)}
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Collapse>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  )
}

// results:
// [
// 	{
// 		photoUrl: str
// 		firstName: str
// 		lastName: str
// 		linkedinUrl: str
// 		country: str
// 		title: str
// 		organizationName: str
// 		organizationWebsite: str
// 		personDescription: str
// 		updatedOn: str
// 		skills: List[str]
// 	}
// ]
export const EmployeeSearchResultsContainer = ({results}) => {
  const [openItems, setOpenItems] = useState({})
  const toggleItem = (id) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }

  return (
    <Container fluid>
      <ListGroup variant='flush'>
        {results.map((result, index) => (
          <ListGroup.Item
            key={index}
            style={{padding: '0.25rem 0.75rem 0.25rem 0.75rem'}}
          >
            <Row
              onClick={() => toggleItem(index)}
              className='clickable transition-fast t-background-color--s-light'
            >
              <Col
                xs={1}
                className='d-flex align-items-center justify-content-start'
              >
                {getImage(result?.photoUrl, 'person_search')}
              </Col>
              <Col
                xs={5}
                className='d-flex flex-column justify-content-center'
              >
                {recordHeader(`${result?.firstName} ${result?.lastName}`)}
                {isTruthy(result?.linkedinUrl) &&
                  recordLink(result?.linkedinUrl, 'linkedin')}
              </Col>
              <Col
                xs={4}
                className='d-flex flex-column justify-content-around'
              >
                {isTruthy(result?.country) && (
                  <InfoRow
                    icon='globe'
                    text={result.country}
                  />
                )}
                {isTruthy(result?.title) && (
                  <InfoRow
                    icon='business_center'
                    text={result.title}
                  />
                )}
              </Col>
            </Row>
            <Collapse
              in={openItems[index]}
              mountOnEnter
              unmountOnExit
            >
              <div id={`details-${index}`}>
                <Row>
                  <Col
                    xs={6}
                    className='d-flex flex-column'
                  >
                    {isTruthy(result?.personDescription) &&
                      recordDescription(result.personDescription)}
                    {isTruthy(
                      result?.organizationName || result?.organizationWebsite
                    ) && (
                      <Stack
                        style={{margin: 0, padding: '0.5rem 0 0.5rem 0'}}
                        direction='horizontal'
                        gap={3}
                      >
                        {result.organizationName && (
                          <InfoRow
                            icon='factory'
                            text={`${result.organizationName}`}
                          />
                        )}
                        {result?.organizationWebsite &&
                          recordLink(result?.organizationWebsite)}
                      </Stack>
                    )}
                  </Col>
                  <Col xs={4}>
                    {isTruthy(result?.updatedOn) && (
                      <InfoRow
                        icon='update'
                        text={result.updatedOn}
                      />
                    )}
                    {isTruthy(result?.skills) && (
                      <div className='d-flex align-items-center'>
                        {itemsRow('keyboard_double_arrow_up', result?.skills)}
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Collapse>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  )
}

const defaultFieldMapping = {
  label: 'label',
  usedInCampaign: 'used_in_campaign',
  createdAt: 'created_at',
  updatedAt: 'updated_at',
  peopleInAudience: 'people_in_audience',
  companiesInAudience: 'companies_in_audience',
  outreachStage: 'outreach_stage',
  outreachMethod: 'outreach_method',
  campaignStatus: 'campaign_status'
}

export const ResourcesContainer = ({results, fieldMapping, iconName, onClick}) => {
  const mappings = {...defaultFieldMapping, ...fieldMapping}

  return (
    <Container fluid>
      <ListGroup variant='flush'>
        {results?.map((result, index) => (
          <ListGroup.Item
            key={index}
            style={{padding: '0.25rem 0.75rem 0.25rem 0.75rem'}}
          >
            <Row
              className='clickable transition-fast t-background-color--s-light'
              onClick={() => onClick(result)}
            >
              <Col
                xs={'auto'}
                className='d-flex align-items-center justify-content-center'
              >
                <span
                  className='material-symbols-outlined color--s-dark'
                  style={{fontSize: '1.44rem'}}
                >
                  {iconName}
                </span>
              </Col>
              <Col
                xs={4}
                className='d-flex align-items-center justify-content-start'
              >
                {recordHeader(result[mappings.label], true)}
              </Col>
              <Col xs={3}>
                {result[mappings.companiesInAudience] && (
                  <InfoRow
                    icon='source_environment'
                    text={`${MatchesFoundRounder(
                      result[mappings.companiesInAudience]
                    )}`}
                    condensed
                  />
                )}
                {result[mappings.peopleInAudience] && (
                  <InfoRow
                    icon='groups'
                    text={`${MatchesFoundRounder(result[mappings.peopleInAudience])}`}
                    condensed
                  />
                )}
                {result[mappings.outreachMethod] && (
                  <>
                    {result[mappings.outreachMethod] === 'email' && (
                      <InfoRow
                        icon='mail'
                        text={'Email Outreach'}
                        condensed
                      />
                    )}
                  </>
                )}
                {result[mappings.outreachStage] && (
                  <>
                    {result[mappings.outreachStage] === 'initial_message' ? (
                      <InfoRow
                        icon='arrow_outward'
                        text={'Initial Message'}
                        condensed
                      />
                    ) : (
                      <InfoRow
                        icon='keyboard_return'
                        text={'Follow-Up Message'}
                        condensed
                      />
                    )}
                  </>
                )}
              </Col>
              <Col />
              <Col
                xs={1}
                className='d-flex align-items-center justify-content-end'
              >
                {result[mappings.usedInCampaign] && (
                  <span
                    className='material-symbols-outlined'
                    style={{fontSize: '1.44rem', color: 'var(--success)'}}
                    title={'Used in campaign.'}
                  >
                    campaign
                  </span>
                )}
                {result[mappings.campaignStatus] && (
                  <InfoRow
                    icon={
                      getCampaignStatusDetails(
                        result[mappings.campaignStatus],
                        result[mappings.campaignReviewReason]
                      )?.icon
                    }
                    text={formatUnderscoreString(result[mappings.campaignStatus])}
                  />
                )}
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  )
}
