import {useState} from 'react'
import {useNavigate, Link} from 'react-router-dom'

import {Form, Row, Col, FloatingLabel, InputGroup, Stack} from 'react-bootstrap'
import {Paper, Divider} from '@mui/material'

import {Button} from '../../atoms/Button.js'

import {InfoPageTemplate} from '../../templates/InfoPageTemplate.js'

import {usePostUserWithEmailAndPassword} from '../../../hooks/server/useUserServerState.js'

function generateDisabledHelpText(
  dpaAccepted,
  termsAccepted,
  privacyAccepted,
  password,
  confirmPassword
) {
  const issues = []

  if (!dpaAccepted) {
    issues.push('You must accept the data processing agreement.')
  }

  if (!termsAccepted) {
    issues.push('You must accept the terms and conditions.')
  }

  if (!privacyAccepted) {
    issues.push('You must accept the privacy policy.')
  }

  if (password !== confirmPassword) {
    issues.push('Passwords must match.')
  }

  return issues.length > 0 ? issues.join('\n') : null
}

export const SignUp = () => {
  const navigate = useNavigate()

  const [emailAddress, setEmailAddress] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [privacyAccepted, setPrivacyAccepted] = useState(false)
  const [dpaAccepted, setDpaAccepted] = useState(false)

  const canSubmit =
    dpaAccepted && termsAccepted && privacyAccepted && password === confirmPassword
  const disabledText = generateDisabledHelpText(
    dpaAccepted,
    termsAccepted,
    privacyAccepted,
    password,
    confirmPassword
  )

  const {
    mutate: postUserWithEmailAndPassword,
    isPending: postUserWithEmailAndPasswordIsPending
  } = usePostUserWithEmailAndPassword()

  return (
    <InfoPageTemplate
      showBanner={false}
      showFooter={false}
      showNavBar={false}
    >
      <Row
        className='w-100 vh-100'
        id='sign-in'
      >
        <Col
          xs={12}
          sm={6}
          className='background--s-light d-flex justify-content-center align-items-center'
        >
          <Paper
            elevation={1}
            style={{
              padding: '3rem',
              minWidth: '24rem',
              border: '1px solid var(--primary-light)'
            }}
            className='background--p-light d-flex flex-column justify-content-center transition-slow t-border-color--accent'
          >
            <h2 className='d-text__font--heading color--p-dark'>Register</h2>
            <hr />
            <Form className='d-form'>
              <InputGroup>
                <FloatingLabel label={'Email'}>
                  <Form.Control
                    type='email'
                    placeholder='hello@pitchr.co'
                    value={emailAddress ?? ''}
                    required
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </FloatingLabel>
              </InputGroup>

              <InputGroup>
                <FloatingLabel label={'Password'}>
                  <Form.Control
                    type='password'
                    placeholder='********'
                    value={password ?? ''}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FloatingLabel>
              </InputGroup>

              <InputGroup>
                <FloatingLabel label={'Confirm Password'}>
                  <Form.Control
                    type='password'
                    placeholder='********'
                    value={confirmPassword ?? ''}
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </FloatingLabel>
              </InputGroup>
            </Form>
            <hr />

            <InputGroup>
              <Form.Check
                required
                label={
                  <span style={{padding: 0, wordSpacing: '-0.25rem'}}>
                    {'I accept the '}
                    <span
                      className='color--accent clickable'
                      onClick={() => {
                        navigate('/terms-and-conditions#terms-and-conditions')
                      }}
                    >
                      Terms and Conditions
                    </span>
                  </span>
                }
                feedback='You must accept the terms and conditions.'
                feedbackType='invalid'
                onChange={() => setTermsAccepted(!termsAccepted)}
              />
            </InputGroup>
            <InputGroup>
              <Form.Check
                required
                label={
                  <span style={{padding: 0, wordSpacing: '-0.25rem'}}>
                    {'I accept the '}
                    <span
                      className='color--accent clickable'
                      onClick={() => {
                        navigate('/privacy-policy#privacy-policy')
                      }}
                    >
                      Privacy Policy
                    </span>
                  </span>
                }
                feedback='You must accept the privacy policy.'
                feedbackType='invalid'
                onChange={() => setPrivacyAccepted(!privacyAccepted)}
              />
            </InputGroup>

            <InputGroup>
              <Form.Check
                required
                style={{border: 'none !important'}}
                label={
                  <span
                    style={{
                      padding: 0,
                      wordSpacing: '-0.25rem'
                    }}
                  >
                    {'I accept the '}
                    <span
                      className='color--accent clickable'
                      onClick={() => {
                        navigate('/data-processing-agreement#data-processing-agreement')
                      }}
                    >
                      Data Processing Agreement
                    </span>
                  </span>
                }
                feedback='You must accept the privacy policy.'
                feedbackType='invalid'
                onChange={() => setDpaAccepted(!dpaAccepted)}
              />
            </InputGroup>

            <Button
              children={'Submit'}
              isLoading={postUserWithEmailAndPasswordIsPending}
              disabled={!canSubmit}
              disabledText={disabledText}
              type='button'
              onClick={() => {
                postUserWithEmailAndPassword({emailAddress, password})
              }}
              variant='outline-primary'
              style={{margin: '1rem'}}
            />

            <Divider
              className='color--s-dark'
              style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}
            >
              OR
            </Divider>

            <Stack
              direction='horizontal'
              gap={1}
            >
              <Link
                to='/sign-in'
                children={'Sign In'}
              />
              <p className='d-text__font--subheading d-text__placement--center color--s-dark'>
                /
              </p>
              <Link
                to='/forgot-password'
                children={'Reset Password'}
              />
            </Stack>
          </Paper>
        </Col>
        <Col
          xs={12}
          sm={6}
          className='background--p-light d-flex flex-column justify-content-center align-items-center align-items-sm-start'
        >
          <h1 className='d-text__font--heading color--p-dark'>Welcome Onboard</h1>
          <h3
            className='d-text__font--subheading color--p-dark clickable transition-fast t-color--accent d-flex align-items-center'
            onClick={() => navigate('/contact#contact-us')}
          >
            Questions? Feel free to reach out
            <span
              className={'material-symbols-outlined'}
              style={{fontSize: '2.074rem'}}
            >
              {'arrow_right_alt'}
            </span>
          </h3>
        </Col>
      </Row>
    </InfoPageTemplate>
  )
}
