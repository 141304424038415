import {Container, Col} from 'react-bootstrap'
import {Spinner} from '../../atoms/Spinner.js'
import {InfoPageTemplate} from '../../templates/InfoPageTemplate.js'
import {
  useGetClientConfig,
  useGetStaticContent
} from '../../../hooks/server/useUtilitiesServerState.js'
import {generateStaticContentUrl} from '../../../utils/helpers.js'

const ContentPage = ({textFileName, pdfFileName, pageTitle, section}) => {
  //SERVER STATE
  const {
    data: clientConfig,
    status: clientConfigStatus,
    fetchStatus: clientConfigFetchStatus
  } = useGetClientConfig()

  const {data: file, fetchStatus: fileFetchStatus} = useGetStaticContent({
    server: clientConfig?.staticContentServer,
    bucket: clientConfig?.staticContentBucket,
    file: textFileName,
    enabled: clientConfigStatus === 'success'
  })

  const pdfUrl = generateStaticContentUrl({
    server: clientConfig?.staticContentServer,
    bucket: clientConfig?.staticContentBucket,
    file: pdfFileName
  })

  return (
    <InfoPageTemplate>
      <Container
        fluid
        className='background--p-light d-flex justify-content-center align-items-center'
        style={{paddingTop: '13rem', paddingBottom: '13rem'}}
      >
        <Col
          xxl={8}
          xl={10}
          md={11}
          xs={12}
          className='d-flex flex-column justify-content-center align-items-center'
        >
          <h1
            className='d-text__font--heading color--p-dark'
            style={{marginBottom: '1rem'}}
            id={section}
          >
            {pageTitle}
          </h1>

          {clientConfigFetchStatus === 'fetching' ? (
            <Spinner />
          ) : (
            <a
              href={pdfUrl}
              target='_blank'
              rel='noreferrer'
              className='d-flex align-items-center'
              style={{textDecoration: 'none'}}
            >
              Download PDF
              <span
                className='material-symbols-outlined'
                style={{marginLeft: '0.25rem'}}
              >
                download
              </span>
            </a>
          )}

          {clientConfigFetchStatus === 'fetching' || fileFetchStatus === 'fetching' ? (
            <Spinner />
          ) : (
            <p style={{whiteSpace: 'pre-wrap'}}>{file}</p>
          )}
        </Col>
      </Container>
    </InfoPageTemplate>
  )
}

export const OptOut = () => (
  <ContentPage
    textFileName='/documents/OptOut.txt'
    pdfFileName='/documents/OptOut.pdf'
    pageTitle='Opt Out'
    section='opt-out'
  />
)

export const DataProcessingAgreement = () => (
  <ContentPage
    textFileName='/documents/DataProcessingAgreement.txt'
    pdfFileName='/documents/DataProcessingAgreement.pdf'
    pageTitle='Data Processing Agreement'
    section='data-processing-agreement'
  />
)

export const PrivacyPolicy = () => (
  <ContentPage
    textFileName='/documents/PrivacyPolicy.txt'
    pdfFileName='/documents/PrivacyPolicy.pdf'
    pageTitle='Privacy Policy'
    section='privacy-policy'
  />
)

export const TermsAndConditions = () => (
  <ContentPage
    textFileName='/documents/TermsAndConditions.txt'
    pdfFileName='/documents/TermsAndConditions.pdf'
    pageTitle='Terms and Conditions'
    section='terms-and-conditions'
  />
)
