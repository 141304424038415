import {useEffect, useState} from 'react'
import {Paper} from '@mui/material'

import {Collapse, Dropdown, Row, Col, Container} from 'react-bootstrap'
import {CustomButton} from '../atoms/CustomButton.js'
import {ReactComponent as LinkedinIcon} from '../atoms/assets/Linkedin.svg'

import {WorkflowStepper} from '../organisms/WorkflowStepper.js'
import {AudienceSearchBar} from '../organisms/AudienceSearchBar.js'
import {LoadAudienceModal, SaveAudienceModal} from '../organisms/AudienceModals.js'
import {
  CompanySearchResultsContainer,
  EmployeeSearchResultsContainer
} from '../organisms/SearchResultsContainers.js'

import {SidebarTemplate} from '../templates/SidebarTemplate.js'

import * as SS from '../../hooks/server/useAudiencesServerState.js'
import * as CS from '../../hooks/client/useAudiencesClientState.js'

import {isTruthy} from '../../utils/hooks.js'
import {
  formatDashedString,
  formatDate,
  buildCompanySearchFilters,
  buildPersonSearchFilters
} from '../../utils/helpers.js'
import {MatchesFoundRounder} from '../../utils/calculations.js'

export const AudienceBuilder = () => {
  // GLOBAL CLIENT STATES
  const {
    companySearchSettingsAtom,
    setCompanySearchSettingsAtom,
    setCompanySearchSettingsKey,
    companySearchSettingsFromResponse
  } = CS.useCompanySearchSettings()
  const {
    personSearchSettingsAtom,
    setPersonSearchSettingsAtom,
    setPersonSearchSettingsKey,
    personSearchSettingsFromResponse
  } = CS.usePersonSearchSettings()
  const {
    audienceMetaDataAtom,
    setAudienceMetaDataAtom,
    setAudienceMetaDataKey,
    audienceMetaDataFromResponse
  } = CS.useAudienceMetaData()

  // LOCAL CLIENT STATES
  const [activeStep, setActiveStep] = useState(0)
  const [personSearchIsEnabled, setPersonSearchIsEnabled] = useState(true)
  const [companySearchIsEnabled, setCompanySearchIsEnabled] = useState(true)
  const [showLoadModal, setShowLoadModal] = useState(false)
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [companySearchInput, setCompanySearchInput] = useState({
    params: {
      company_summary: false,
      size: 10
    },
    payload: {}
  })
  const [personSearchInput, setPersonSearchInput] = useState({
    params: {person_summary: false},
    payload: {},
    enabled: false
  })
  const [formattedCompanySearchResults, setFormattedCompanySearchResults] = useState([])
  const [formattedEmployeeSearchResults, setFormattedEmployeeSearchResults] = useState(
    []
  )

  //SERVER QUERY STATES
  const {
    data: cSearchResults,
    fetchStatus: cSearchResultsFetchStatus,
    fetchNextPage: cSearchResultsFetchNextPage,
    hasNextPage: cSearchResultsHasNextPage,
    isFetchingNextPage: cSearchResultsIsFetchingNextPage
  } = SS.usePostSearchCompanies(companySearchInput)

  const {
    data: pSearchResults,
    fetchStatus: pSearchResultsFetchStatus,
    fetchNextPage: pSearchResultsFetchNextPage,
    hasNextPage: pSearchResultsHasNextPage,
    isFetchingNextPage: pSearchResultsIsFetchingNextPage
  } = SS.usePostSearchPeople(personSearchInput)

  const {data: cCountryValues, fetchStatus: cCountryValuesFetchStatus} =
    SS.useGetSearchFilterOptions({
      params: {field: 'company_countries'}
    })
  const {data: cEmployeesValues, fetchStatus: cEmployeesValuesFetchStatus} =
    SS.useGetSearchFilterOptions({
      params: {field: 'company_employees'}
    })
  const {data: cRevenueValues, fetchStatus: cRevenueValuesFetchStatus} =
    SS.useGetSearchFilterOptions({
      params: {field: 'company_revenues'}
    })
  const {data: cIndustryValues, fetchStatus: cIndustryValuesFetchStatus} =
    SS.useGetSearchFilterOptions({
      params: {field: 'company_industries'}
    })
  const {data: cTechnologyValues, fetchStatus: cTechnologyValuesFetchStatus} =
    SS.useGetSearchFilterOptions({
      params: {field: 'company_technologies'}
    })
  const {data: pCountryValues, fetchStatus: pCountryValuesFetchStatus} =
    SS.useGetSearchFilterOptions({
      params: {field: 'person_countries'}
    })
  const {data: pSeniorityValues, fetchStatus: pSeniorityValuesFetchStatus} =
    SS.useGetSearchFilterOptions({
      params: {field: 'person_seniorities'}
    })
  const {data: pDepartmentValues, fetchStatus: pDepartmentValuesFetchStatus} =
    SS.useGetSearchFilterOptions({
      params: {field: 'person_departments'}
    })

  const {data: audiences, fetchStatus: audiencesFetchStatus} = SS.useGetAudiences()

  // SERVER MUTATE STATES
  const {mutate: postAudience, status: postAudienceStatus} = SS.usePostAudience()
  const {mutate: patchAudience, status: patchAudienceStatus} = SS.usePatchAudience()

  // VARIABLES
  const resultsExist =
    (activeStep === 0 && isTruthy(formattedCompanySearchResults)) ||
    (activeStep === 1 && isTruthy(formattedEmployeeSearchResults))

  const steps = [
    {
      header: 'Companies',
      icon: 'source_environment',
      nextOnClick: () => {
        handleSearchCompanies({page_size: 100})
      }
    },
    {
      header: 'Employees',
      icon: 'groups',
      tabOnClick: () => {
        handleSearchCompanies({page_size: 100})
      }
    }
  ]

  const peopleFilters = [
    {
      label: 'Department',
      description: 'Employee Department',
      iconName: 'business_center',
      values: {
        displayAs: 'checkbox',
        available: pDepartmentValues,
        loading: pDepartmentValuesFetchStatus === 'fetching',
        selected: personSearchSettingsAtom.departmentValues,
        setSelected: (selected) =>
          setPersonSearchSettingsKey('departmentValues', selected)
      }
    },
    {
      label: 'Seniority',
      description: 'Employee Seniority',
      iconName: 'tenancy',
      values: {
        displayAs: 'checkbox',
        available: pSeniorityValues,
        loading: pSeniorityValuesFetchStatus === 'fetching',
        selected: personSearchSettingsAtom.seniorityValues,
        setSelected: (selected) =>
          setPersonSearchSettingsKey('seniorityValues', selected)
      }
    },
    {
      label: 'Country',
      description: 'Employee Country',
      iconName: 'globe',
      values: {
        displayAs: 'searchBox',
        available: pCountryValues,
        loading: pCountryValuesFetchStatus === 'fetching',
        selected: personSearchSettingsAtom.countryValues,
        setSelected: (selected) => setPersonSearchSettingsKey('countryValues', selected)
      }
    },
    {
      label: 'Keyword',
      description: 'Employee Keyword Search',
      iconName: 'search',
      values: {
        displayAs: 'multiInput',
        selected: personSearchSettingsAtom.keywordValues,
        setSelected: (selected) => setPersonSearchSettingsKey('keywordValues', selected)
      }
    }
  ]

  const companyFilters = [
    {
      label: 'Country',
      description: 'Company Country HQ',
      iconName: 'globe',
      values: {
        displayAs: 'searchBox',
        available: cCountryValues,
        loading: cCountryValuesFetchStatus === 'fetching',
        selected: companySearchSettingsAtom.countryValues,
        setSelected: (selected) =>
          setCompanySearchSettingsKey('countryValues', selected)
      },
      sign: {
        available: [
          ['equals', '(=) Include'],
          ['notEquals', '(!=) Exclude']
        ],
        selected: companySearchSettingsAtom.countrySign,
        setSelected: (selected) => setCompanySearchSettingsKey('countrySign', selected)
      },
      operator: {
        available: [['or', 'Or']],
        selected: companySearchSettingsAtom.countryOperator,
        setSelected: (selected) =>
          setCompanySearchSettingsKey('countryOperator', selected)
      }
    },
    {
      label: 'Employees',
      description: 'Company Employees',
      iconName: 'group',
      values: {
        displayAs: 'checkbox',
        available: cEmployeesValues,
        loading: cEmployeesValuesFetchStatus === 'fetching',
        selected: companySearchSettingsAtom.employeesValues,
        setSelected: (selected) =>
          setCompanySearchSettingsKey('employeesValues', selected)
      },
      sign: {
        available: [
          ['equals', '(=) Include'],
          ['notEquals', '(!=) Exclude']
        ],
        selected: companySearchSettingsAtom.employeesSign,
        setSelected: (selected) =>
          setCompanySearchSettingsKey('employeesSign', selected)
      },
      operator: {
        available: [['or', 'Or']],
        selected: companySearchSettingsAtom.employeesOperator,
        setSelected: (selected) =>
          setCompanySearchSettingsKey('employeesOperator', selected)
      }
    },
    {
      label: 'Revenue',
      description: 'Company Revenue',
      iconName: 'attach_money',
      values: {
        displayAs: 'checkbox',
        available: cRevenueValues,
        loading: cRevenueValuesFetchStatus === 'fetching',
        selected: companySearchSettingsAtom.revenueValues,
        setSelected: (selected) =>
          setCompanySearchSettingsKey('revenueValues', selected)
      },
      sign: {
        available: [
          ['equals', '(=) Include'],
          ['notEquals', '(!=) Exclude']
        ],
        selected: companySearchSettingsAtom.revenueSign,
        setSelected: (selected) => setCompanySearchSettingsKey('revenueSign', selected)
      },
      operator: {
        available: [['or', 'Or']],
        selected: companySearchSettingsAtom.revenueOperator,
        setSelected: (selected) =>
          setCompanySearchSettingsKey('revenueOperator', selected)
      }
    },
    {
      label: 'Industry',
      description: 'Company Industriy',
      iconName: 'factory',
      values: {
        displayAs: 'searchBox',
        available: cIndustryValues,
        loading: cIndustryValuesFetchStatus === 'fetching',
        selected: companySearchSettingsAtom.industryValues,
        setSelected: (selected) =>
          setCompanySearchSettingsKey('industryValues', selected)
      },
      sign: {
        available: [
          ['equals', '(=) Include'],
          ['notEquals', '(!=) Exclude']
        ],
        selected: companySearchSettingsAtom.industrySign,
        setSelected: (selected) => setCompanySearchSettingsKey('industrySign', selected)
      },
      operator: {
        available: [['or', 'Or']],
        selected: companySearchSettingsAtom.industryOperator,
        setSelected: (selected) =>
          setCompanySearchSettingsKey('industryOperator', selected)
      }
    },
    {
      label: 'Technology',
      description: 'Company Technologies',
      iconName: 'biotech',
      values: {
        displayAs: 'searchBox',
        available: cTechnologyValues,
        loading: cTechnologyValuesFetchStatus === 'fetching',
        selected: companySearchSettingsAtom.technologyValues,
        setSelected: (selected) =>
          setCompanySearchSettingsKey('technologyValues', selected)
      },
      sign: {
        available: [
          ['equals', '(=) Include'],
          ['notEquals', '(!=) Exclude']
        ],
        selected: companySearchSettingsAtom.technologySign,
        setSelected: (selected) =>
          setCompanySearchSettingsKey('technologySign', selected)
      },
      operator: {
        available: [
          ['or', 'Or'],
          ['and', 'And']
        ],
        selected: companySearchSettingsAtom.technologyOperator,
        setSelected: (selected) =>
          setCompanySearchSettingsKey('technologyOperator', selected)
      }
    },
    {
      label: 'LinkedIn',
      description: 'Company LinkedIn Page',
      IconComponent: LinkedinIcon,
      values: {
        displayAs: 'multiInput',
        selected: companySearchSettingsAtom.linkedinIdValues,
        setSelected: (selected) =>
          setCompanySearchSettingsKey('linkedinIdValues', selected)
      },
      sign: {
        available: [['notEquals', '(!=) Exclude']],
        selected: companySearchSettingsAtom.linkedinIdSign,
        setSelected: (selected) =>
          setCompanySearchSettingsKey('linkedinIdSign', selected)
      },
      operator: {
        available: [['or', 'Or']],
        selected: companySearchSettingsAtom.linkedinIdOperator,
        setSelected: (selected) =>
          setCompanySearchSettingsKey('linkedinIdOperator', selected)
      }
    },
    {
      label: 'Domain',
      description: 'Company Domain',
      iconName: 'language',
      values: {
        displayAs: 'multiInput',
        selected: companySearchSettingsAtom.domainValues,
        setSelected: (selected) => setCompanySearchSettingsKey('domainValues', selected)
      },
      sign: {
        available: [['notEquals', '(!=) Exclude']],
        selected: companySearchSettingsAtom.domainSign,
        setSelected: (selected) => setCompanySearchSettingsKey('domainSign', selected)
      },
      operator: {
        available: [['or', 'Or']],
        selected: companySearchSettingsAtom.domainOperator,
        setSelected: (selected) =>
          setCompanySearchSettingsKey('domainOperator', selected)
      }
    },
    {
      label: 'Name',
      description: 'Company Name',
      iconName: 'source_environment',
      values: {
        displayAs: 'multiInput',
        selected: companySearchSettingsAtom.nameValues,
        setSelected: (selected) => setCompanySearchSettingsKey('nameValues', selected)
      },
      sign: {
        available: [['equals', 'Equals']],
        selected: companySearchSettingsAtom.nameSign,
        setSelected: (selected) => setCompanySearchSettingsKey('nameSign', selected)
      },
      operator: {
        available: [['or', 'Or']],
        selected: companySearchSettingsAtom.nameOperator,
        setSelected: (selected) => setCompanySearchSettingsKey('nameOperator', selected)
      }
    }
  ]

  // HANDLERS
  const handleLoadAudience = (audienceId) => {
    const audience = audiences.find((a) => a?.audience_id === audienceId)
    setCompanySearchSettingsAtom(companySearchSettingsFromResponse(audience))
    setPersonSearchSettingsAtom(personSearchSettingsFromResponse(audience))
    setAudienceMetaDataAtom(audienceMetaDataFromResponse(audience))
  }

  const handlePostAudience = () => {
    const payload = {
      companies_in_audience: audienceMetaDataAtom.companiesInAudience,
      people_in_audience: audienceMetaDataAtom.peopleInAudience,
      label: audienceMetaDataAtom.label,
      used_in_campaign: false,
      company_search_filters: buildCompanySearchFilters(companySearchSettingsAtom),
      person_search_filters: buildPersonSearchFilters(personSearchSettingsAtom)
    }
    postAudience(
      {payload: payload},
      {
        onSuccess: (r) => {
          setAudienceMetaDataKey('audienceId', r.audience_id)
        }
      }
    )
  }

  const handlePatchAudience = () => {
    const params = {audience_id: audienceMetaDataAtom?.audienceId}
    const payload = {
      companies_in_audience: audienceMetaDataAtom.companiesInAudience,
      people_in_audience: audienceMetaDataAtom.peopleInAudience,
      company_search_filters: buildCompanySearchFilters(companySearchSettingsAtom),
      person_search_filters: buildPersonSearchFilters(personSearchSettingsAtom)
    }
    patchAudience({params: params, payload: payload})
  }

  const handleSearchCompanies = ({page_size = 10}) => {
    const params = {company_summary: false, size: page_size}
    const payload = buildCompanySearchFilters(companySearchSettingsAtom)
    setCompanySearchInput({params, payload})
    setCompanySearchIsEnabled(false)
  }

  const handleSearchPeople = () => {
    const params = {
      person_summary: false,
      companies_found: audienceMetaDataAtom.companiesInAudience
    }
    const payload = buildPersonSearchFilters(personSearchSettingsAtom)
    const enabled = true
    setPersonSearchInput({params, payload, enabled})
    setPersonSearchIsEnabled(false)
  }

  // LISTENERS
  useEffect(() => {
    setCompanySearchIsEnabled(true)
    setPersonSearchIsEnabled(true)
  }, [companySearchSettingsAtom])

  useEffect(() => {
    setPersonSearchIsEnabled(true)
  }, [personSearchSettingsAtom])

  useEffect(() => {
    if (!cSearchResults || !cSearchResults.pages) {
      setFormattedCompanySearchResults([])
      return
    }

    const allCompanies = cSearchResults.pages.flatMap((page) => page.companies)
    const supportedSocials = [
      'facebook',
      'github',
      'instagram',
      'linkedin',
      'linkedinIdAlpha',
      'pinterest',
      'twitter',
      'youtube'
    ]

    const normalized = allCompanies.map((company) => {
      const socialProps = {}
      supportedSocials.forEach((social) => {
        socialProps[social] = company?.socialNetworks?.[social] || null
      })

      return {
        name: company?.name || '',
        description: company?.description || '',
        ...socialProps,
        website: company?.domain
          ? company?.domain?.startsWith('http')
            ? company?.domain
            : `https://${company?.domain}`
          : '',
        revenue: company?.revenue?.toUpperCase() || '',
        employees: company?.totalEmployees?.toUpperCase() || '',
        country: company?.country?.name || '',
        industry: formatDashedString(company?.industryMain) || '',
        logo: company?.logo || '',
        technologies: company?.technologies?.map((t) => formatDashedString(t)) || [],
        yearFounded: company?.yearFounded || ''
      }
    })
    const linkedInIds = normalized.map((c) => c?.linkedinIdAlpha).filter((id) => !!id)

    setFormattedCompanySearchResults(normalized)
    setAudienceMetaDataKey(
      'companiesInAudience',
      cSearchResults?.pages?.[0]?.meta?.total
    )
    setPersonSearchSettingsKey('organizationLinkedinPublicIdentifiers', linkedInIds)
  }, [cSearchResults])

  useEffect(() => {
    if (!pSearchResults || !pSearchResults.pages) {
      setFormattedEmployeeSearchResults([])
      return
    }

    const allPeople = pSearchResults.pages.flatMap((page) => page.search_results)

    const normalized = allPeople.map((item) => {
      const {date_updated, role_title, person, organization} = item
      const countryObj = person?.location?.osm_locations?.find(
        (loc) => loc.place_type === 'country'
      )
      const country = countryObj?.name || ''
      const linkedinUrl = person?.linkedin_info?.public_profile_url || ''
      const skills = person?.skills?.map((skill) => skill.name) || []
      const organizationName = organization?.name || ''
      const organizationWebsite = organization?.website
        ? organization.website.startsWith('http')
          ? organization.website
          : `https://${organization.website}`
        : ''

      return {
        photoUrl: person?.photo || '',
        firstName: person?.first_name || '',
        lastName: person?.last_name || '',
        linkedinUrl: linkedinUrl,
        country: country,
        title: role_title || person?.headline || '',
        organizationName: organizationName,
        organizationWebsite: organizationWebsite,
        personDescription: person?.description || '',
        updatedOn: formatDate(date_updated, false) || '',
        skills: skills
      }
    })
    const uniqueNormalized = []
    const seenLinkedinUrls = new Set()

    for (const person of normalized) {
      if (person.linkedinUrl) {
        if (!seenLinkedinUrls.has(person.linkedinUrl)) {
          seenLinkedinUrls.add(person.linkedinUrl)
          uniqueNormalized.push(person)
        }
      } else {
        uniqueNormalized.push(person)
      }
    }

    setFormattedEmployeeSearchResults(uniqueNormalized)
    setAudienceMetaDataKey(
      'peopleInAudience',
      pSearchResults?.pages?.[0]?.total_search_results
    )
  }, [pSearchResults])

  return (
    <>
      <LoadAudienceModal
        show={showLoadModal}
        onHide={() => setShowLoadModal(false)}
        audiences={audiences}
        audiencesFetchStatus={audiencesFetchStatus}
        handleLoadAudience={handleLoadAudience}
      />
      <SaveAudienceModal
        show={showSaveModal}
        onHide={() => {
          setShowSaveModal(false)
        }}
        audiences={audiences}
        audiencesFetchStatus={audiencesFetchStatus}
        postAudience={handlePostAudience}
        postAudienceStatus={postAudienceStatus}
        patchAudience={handlePatchAudience}
        patchAudienceStatus={patchAudienceStatus}
        audienceMetaDataAtom={audienceMetaDataAtom}
        setAudienceMetaDataKey={setAudienceMetaDataKey}
        personSearchIsEnabled={personSearchIsEnabled}
        companySearchIsEnabled={companySearchIsEnabled}
        personSearchSettingsAtom={personSearchSettingsAtom}
        companySearchSettingsAtom={companySearchSettingsAtom}
      />
      <SidebarTemplate>
        <Paper
          className='d-flex flex-column align-items-center background--p-light'
          variant='outlined'
          style={{
            padding: '0',
            borderRadius: '0rem 0rem 1rem 1rem',
            width: 'fit-content',
            alignSelf: 'center'
          }}
        >
          <Collapse
            in={activeStep === 0}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <AudienceSearchBar filters={companyFilters} />
            </div>
          </Collapse>
          <Collapse
            in={activeStep === 1}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <AudienceSearchBar filters={peopleFilters} />
            </div>
          </Collapse>
        </Paper>

        <Paper
          className='d-flex flex-column align-items-center background--p-light'
          variant='outlined'
          style={{padding: '1rem', borderRadius: '1rem', margin: '2rem'}}
        >
          <Container fluid>
            <div className='d-flex justify-content-between align-items-center'>
              <h5 className='d-text__font--heading color--p-dark'>{`Audience ${
                isTruthy(audienceMetaDataAtom?.label)
                  ? ` - ${audienceMetaDataAtom?.label}`
                  : ''
              }`}</h5>
              {activeStep === 0 ? (
                <CustomButton
                  onClick={handleSearchCompanies}
                  label={
                    companySearchIsEnabled
                      ? 'Search'
                      : cSearchResultsFetchStatus === 'fetching'
                      ? 'Searching'
                      : 'Search Complete'
                  }
                  iconName={companySearchIsEnabled ? 'search' : 'search_check'}
                  isLoading={cSearchResultsFetchStatus === 'fetching'}
                  isDisabled={!companySearchIsEnabled}
                  disabledText={'The results are already up to date!'}
                  style={{margin: '0 1rem 0.25rem auto'}}
                />
              ) : (
                <CustomButton
                  onClick={handleSearchPeople}
                  label={
                    personSearchIsEnabled
                      ? 'Search'
                      : pSearchResultsFetchStatus === 'fetching'
                      ? 'Searching'
                      : 'Search Complete'
                  }
                  iconName={personSearchIsEnabled ? 'search' : 'search_check'}
                  isLoading={
                    pSearchResultsFetchStatus === 'fetching' ||
                    cSearchResultsFetchStatus === 'fetching'
                  }
                  isDisabled={!personSearchIsEnabled}
                  disabledText={'The results are already up to date!'}
                  style={{margin: '0 1rem 0.25rem auto'}}
                />
              )}

              <Dropdown drop='start'>
                <Dropdown.Toggle
                  variant='none'
                  bsPrefix='dropdownToggle'
                  style={{border: 'none', padding: 0}}
                  children={
                    <span
                      className='material-symbols-outlined transition-fast clickable t-scale--small t-color--accent'
                      style={{fontSize: '2rem', color: 'black'}}
                    >
                      {'more_horiz'}
                    </span>
                  }
                />
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setShowLoadModal(true)}>
                    Load Audience
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setShowSaveModal(true)}>
                    Save Audience
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <hr style={{margin: 0}} />

            <Row>
              <Col
                xs='auto'
                className='d-flex flex-column justify-content-between'
                style={{paddingTop: '0.5rem'}}
              >
                <WorkflowStepper
                  steps={steps}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
                {resultsExist && (
                  <p
                    style={{margin: 0, padding: 0}}
                    className='d-flex align-items-center'
                  >
                    <span
                      className='material-symbols-outlined transition-fast clickable t-scale--small t-color--accent'
                      style={{fontSize: '1.728rem', color: 'black'}}
                    >
                      {'search_check'}
                    </span>
                    {activeStep === 0
                      ? MatchesFoundRounder(audienceMetaDataAtom?.companiesInAudience)
                      : MatchesFoundRounder(audienceMetaDataAtom?.peopleInAudience)}
                  </p>
                )}
              </Col>
              <Col
                className='d-flex flex-column align-items-stretch align-content-center'
                style={{
                  borderLeft: 'solid 1px var(--bs-border-color)',
                  padding: '0 1rem 0 0',
                  maxHeight: '70vh',
                  overflowY: 'auto'
                }}
              >
                <Collapse
                  in={activeStep === 0}
                  mountOnEnter
                  unmountOnExit
                >
                  <div>
                    <CompanySearchResultsContainer
                      results={formattedCompanySearchResults}
                    />
                  </div>
                </Collapse>
                <Collapse
                  in={activeStep === 1}
                  mountOnEnter
                  unmountOnExit
                >
                  <div>
                    <EmployeeSearchResultsContainer
                      results={formattedEmployeeSearchResults}
                    />
                  </div>
                </Collapse>

                {!resultsExist &&
                  !personSearchIsEnabled &&
                  pSearchResultsFetchStatus !== 'fetching' && (
                    <div className='d-flex align-items-center justify-content-center'>
                      <h6 style={{paddingTop: '1rem'}}>No Results Found</h6>
                    </div>
                  )}

                {resultsExist && (
                  <>
                    <hr style={{marginTop: 0}} />
                    <CustomButton
                      onClick={
                        activeStep === 0
                          ? cSearchResultsFetchNextPage
                          : pSearchResultsFetchNextPage
                      }
                      label={'Load More'}
                      iconName={'search'}
                      isLoading={
                        activeStep === 0
                          ? cSearchResultsIsFetchingNextPage
                          : pSearchResultsIsFetchingNextPage
                      }
                      isDisabled={
                        activeStep === 0
                          ? !cSearchResultsHasNextPage
                          : !pSearchResultsHasNextPage
                      }
                      disabledText={'No more records exist'}
                      style={{alignSelf: 'center'}}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </Paper>
      </SidebarTemplate>
    </>
  )
}
