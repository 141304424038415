import axios from 'axios'
import {generateStaticContentUrl} from '../utils/helpers.js'

export const getClientConfig = async () => {
  const {data} = await axios.get('/api/utilities/configuration')
  return data
}

export const getKeepAlive = async () => {
  const {data} = await axios.get('/api/utilities/keep-alive')
  return data
}

export const getStaticContent = async ({server, bucket, file}) => {
  const url = generateStaticContentUrl({server, bucket, file})
  const {data} = await axios.get(url)
  return data
}
