import {useQuery} from '@tanstack/react-query'
import * as utilitiesApi from '../../services/utilitiesApi.js'

export const useGetClientConfig = () => {
  return useQuery({
    queryKey: ['utilitiesConfig', '1'],
    queryFn: () => utilitiesApi.getClientConfig(),
    staleTime: 7 * 24 * 60 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    onError: () => {
      console.error('Failed initializing the application!')
    }
  })
}

export const useGetKeepAlive = () => {
  return useQuery({
    queryKey: ['keepAlive'],
    queryFn: () => utilitiesApi.getKeepAlive(),
    staleTime: 2 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
    onError: () => {
      console.error('Failed retrieving services health!')
    }
  })
}

export const useGetStaticContent = ({server, bucket, file, enabled = true}) => {
  return useQuery({
    queryKey: ['staticContent', file],
    queryFn: () => utilitiesApi.getStaticContent({server, bucket, file}),
    staleTime: 7 * 24 * 60 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    enabled: !!enabled,
    onError: () => {
      console.error('Failed retrieving file!')
    }
  })
}
