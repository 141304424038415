import {useNavigate} from 'react-router-dom'
import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery
} from '@tanstack/react-query'
import * as campaignsApi from '../../services/campaignsApi.js'
import {errorToast, successToast} from '../../components/atoms/Toasts.js'

export const useGetCampaignReport = ({params} = {}) => {
  return useQuery({
    queryKey: ['campaign-report', params],
    queryFn: () => campaignsApi.getCampaignReport({params}),
    staleTime: 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    enabled: false,
    meta: {
      errorMessage: 'Generating Campaign Report Failed.\nPlease try again later.'
    }
  })
}
export const useGetCampaignMetaData = ({params = {}, enabled = true} = {}) => {
  return useQuery({
    queryKey: ['campaignMetaData', params],
    queryFn: () => campaignsApi.getCampaignMetaData({params}),
    staleTime: 30 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    enabled: !!enabled,
    meta: {
      errorMessage: 'Fetching Campaign Data Failed.\nPlease try again later.'
    }
  })
}
export const useGetCampaigns = ({params} = {}) => {
  return useQuery({
    queryKey: ['campaigns', params],
    queryFn: () => campaignsApi.getCampaigns({params}),
    staleTime: 30 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    meta: {errorMessage: 'Fetching Campaigns Failed.\nPlease try again later.'}
  })
}
export const usePostCampaign = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation({
    mutationFn: ({payload}) => campaignsApi.postCampaign({payload}),
    onSuccess: (data) => {
      queryClient.invalidateQueries({queryKey: ['campaigns']})
      successToast(
        <span>
          {`Campaign "${data?.campaign_name}" has been created!\nYou can view it `}
          <span
            className='color--accent clickable'
            onClick={() => {
              navigate(`/campaign-insights/?campaign_id=${data?.campaign_id}`)
            }}
          >
            here
          </span>
        </span>
      )
    },
    onError: () => {
      errorToast('Saving Campaign Failed.\nPlease try again later.')
    }
  })
}
export const usePatchCampaign = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params, payload}) => campaignsApi.patchCampaign({params, payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['campaigns']})
      successToast('Campaign Updated!')
    },
    onError: () => {
      errorToast('Updating Campaign Failed.\nPlease try again later.')
    }
  })
}
export const useDeleteCampaign = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params}) => campaignsApi.deleteCampaign({params}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['campaigns']})
      successToast('Campaign Deleted!')
    },
    onError: () => {
      errorToast('Deleting Campaign Failed.\nPlease try again later.')
    }
  })
}
export const usePostCampaignEmailsSend = () => {
  return useMutation({
    mutationFn: ({params, payload}) =>
      campaignsApi.postCampaignEmailsSend({params, payload}),
    onSuccess: () => {
      successToast('Email Sent!')
    },
    onError: () => {
      errorToast('Sending Email Failed.\nPlease try again later.')
    }
  })
}
export const usePostCampaignsOutreachTrackerPeopleSearch = ({
  params = {},
  payload = {},
  enabled = true
} = {}) => {
  return useInfiniteQuery({
    queryKey: ['campaignsOutreachTrackerPeopleSearch', params, payload],
    queryFn: ({pageParam = 1}) =>
      campaignsApi.postCampaignsOutreachTrackerPeopleSearch({
        params: {...params, page: pageParam},
        payload: payload
      }),
    getNextPageParam: (lastPage) => {
      return lastPage.has_next ? lastPage.page + 1 : undefined
    },
    staleTime: 30 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    enabled: !!enabled,
    meta: {
      errorMessage:
        'Retrieving Campaign Outreach Information Failed.\nPlease try again later.'
    }
  })
}

export const usePostCampaignsOutreachTrackerCompaniesSearch = ({
  params = {},
  payload = {},
  enabled = true
}) => {
  return useInfiniteQuery({
    queryKey: ['campaignsOutreachTrackerCompanies', params, payload],
    queryFn: ({pageParam = 1}) =>
      campaignsApi.postCampaignsOutreachTrackerCompaniesSearch({
        params: {...params, page: pageParam},
        payload: payload
      }),
    getNextPageParam: (lastPage) => {
      return lastPage.has_next ? lastPage.page + 1 : undefined
    },
    staleTime: 30 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    enabled: !!enabled,
    meta: {
      errorMessage:
        'Retrieving Campaign Outreach Information Failed.\nPlease try again later.'
    }
  })
}
