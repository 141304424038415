import {Container} from 'react-bootstrap'
import {useGetKeepAlive} from '../../hooks/server/useUtilitiesServerState.js'
import {isTruthy} from '../../utils/hooks.js'

export const BaseTemplate = ({children}) => {
  const {error} = useGetKeepAlive()

  if (isTruthy(error)) {
    console.log(`Failed loading services health state ${error}`)
  }

  return (
    <Container
      fluid
      className='blurred-pattern-background'
    >
      {children}
    </Container>
  )
}
