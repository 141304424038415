//ATOMS
import {
  Form,
  Row,
  Col,
  FloatingLabel,
  Container,
  OverlayTrigger,
  Collapse,
  Tooltip,
  Dropdown,
  InputGroup
} from 'react-bootstrap'
import {useRef, useState, useEffect, useMemo, memo} from 'react'
import {Spinner} from '../atoms/Spinner.js'
import {ReactComponent as LinkedinIcon} from '../atoms/assets/Linkedin.svg'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent
} from '@mui/lab'

//MOLECULES
import {DynamicInputGroup} from '../molecules/DynamicInputGroup.js'

//ORGANISMS
import {MessageModal, LoadMessageModal, GenerateMessageModal} from './MessageModals.js'
import {
  SignatureModal,
  SaveMessageInstructionsDialog,
  LoadMessageInstructionsDialog
} from './MessageInstructionsModals.js'
import {WorkflowStepper} from '../organisms/WorkflowStepper.js'

//UTILS
import {useAutosizeTextArea} from '../../utils/hooks.js'
import {InputWrapper, isTruthy} from '../../utils/hooks.js'

const ProfileInformationForm = memo(
  ({
    companyNameError,
    firstNameError,
    lastNameError,
    messageInstructionsAtom,
    setMessageInstructionsKey,
    supportedLanguages,
    supportedLanguagesFetchStatus,
    titleError,
    userProfile,
    userProfileFetchStatus,
    valuePropositionError
  }) => {
    const valuePropositionRef = useRef(null)
    useAutosizeTextArea(valuePropositionRef, messageInstructionsAtom?.valueProposition)

    return (
      <Form className='d-form'>
        {/* First and Last Name Input Groups */}
        <Form.Group as={Row}>
          <InputWrapper
            tooltipMessage={firstNameError}
            tooltipId={'first-name-tooltip'}
          >
            <InputGroup
              as={Col}
              style={{marginRight: '0.75rem'}}
            >
              <FloatingLabel label='First Name'>
                <Form.Control
                  type='text'
                  value={userProfile?.first_name || ''}
                  readOnly
                  placeholder=''
                  style={{borderRight: 'none'}}
                  className={!!firstNameError ? 'invalidInput' : ''}
                />
              </FloatingLabel>
              <InputGroup.Text className={!!firstNameError ? 'invalidInput' : ''}>
                {userProfileFetchStatus === 'fetching' && (
                  <Spinner
                    size='sm'
                    animation='grow'
                  />
                )}
              </InputGroup.Text>
            </InputGroup>
          </InputWrapper>
          <InputWrapper
            tooltipMessage={lastNameError}
            tooltipId={'last-name-tooltip'}
          >
            <InputGroup as={Col}>
              <FloatingLabel label='Last Name'>
                <Form.Control
                  type='text'
                  value={userProfile?.last_name || ''}
                  readOnly
                  placeholder=''
                  style={{borderRight: 'none'}}
                  className={!!lastNameError ? 'invalidInput' : ''}
                />
              </FloatingLabel>
              <InputGroup.Text className={!!lastNameError ? 'invalidInput' : ''}>
                {userProfileFetchStatus === 'fetching' && (
                  <Spinner
                    size='sm'
                    animation='grow'
                  />
                )}
              </InputGroup.Text>
            </InputGroup>
          </InputWrapper>
        </Form.Group>

        {/* Job Title and Preferred Language */}
        <Form.Group as={Row}>
          <InputWrapper
            tooltipMessage={titleError}
            tooltipId={'job-title-tooltip'}
          >
            <InputGroup
              as={Col}
              style={{marginRight: '0.75rem'}}
            >
              <FloatingLabel label='Your Job Title'>
                <Form.Control
                  className={!!titleError ? 'invalidInput' : ''}
                  type='text'
                  placeholder=''
                  value={messageInstructionsAtom?.title || ''}
                  onChange={(e) => setMessageInstructionsKey('title', e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      e.target.blur()
                    }
                  }}
                />
              </FloatingLabel>
            </InputGroup>
          </InputWrapper>
          <Col
            className={
              supportedLanguagesFetchStatus === 'fetching'
                ? 'd-flex justify-content-center align-items-center'
                : 'd-flex justify-content-stretch align-items-stretch'
            }
            style={{padding: 0}}
          >
            {supportedLanguagesFetchStatus === 'fetching' ? (
              <Spinner />
            ) : (
              <FloatingLabel
                label='Preferred Language'
                style={{width: '100%'}}
              >
                <Form.Select
                  value={messageInstructionsAtom?.language}
                  onChange={(e) =>
                    setMessageInstructionsKey('language', e.target.value)
                  }
                >
                  {supportedLanguages?.map((lang) => (
                    <option
                      key={lang.language}
                      value={lang.language}
                    >
                      {lang.language}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            )}
          </Col>
        </Form.Group>

        {/* Company Name */}
        <Form.Group as={Row}>
          <InputWrapper
            tooltipMessage={companyNameError}
            tooltipId={'company-name-tooltip'}
          >
            <InputGroup as={Col}>
              <FloatingLabel label='Your Company Name'>
                <Form.Control
                  type='text'
                  placeholder=''
                  value={messageInstructionsAtom?.companyName || ''}
                  onChange={(e) =>
                    setMessageInstructionsKey('companyName', e.target.value)
                  }
                  className={!!companyNameError ? 'invalidInput' : ''}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      e.target.blur()
                    }
                  }}
                />
              </FloatingLabel>
            </InputGroup>
          </InputWrapper>
        </Form.Group>

        {/* Value Proposition */}
        <Form.Group as={Row}>
          <InputWrapper
            tooltipMessage={valuePropositionError}
            tooltipId={'value-proposition-tooltip'}
          >
            <InputGroup as={Col}>
              <FloatingLabel label='Your Value Proposition'>
                <Form.Control
                  as='textarea'
                  placeholder=''
                  ref={valuePropositionRef}
                  rows={1}
                  value={messageInstructionsAtom?.valueProposition || ''}
                  onChange={(e) =>
                    setMessageInstructionsKey('valueProposition', e.target.value)
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      e.target.blur()
                    }
                  }}
                  className={valuePropositionError ? 'invalidInput' : ''}
                />
              </FloatingLabel>
            </InputGroup>
          </InputWrapper>
        </Form.Group>
      </Form>
    )
  }
)

const PreferencesForm = memo(
  ({
    messageInstructionsAtom,
    setMessageInstructionsKey,
    intentionError,
    inputValidator
  }) => {
    const intentionRef = useRef(null)
    useAutosizeTextArea(intentionRef, messageInstructionsAtom?.intention)

    return (
      <Form className='d-form'>
        <Form.Group as={Row}>
          <InputWrapper
            tooltipMessage={intentionError}
            tooltipId={'intention-tooltip'}
          >
            <InputGroup as={Col}>
              <FloatingLabel label='Your Intention'>
                <Form.Control
                  as='textarea'
                  ref={intentionRef}
                  rows={1}
                  name='intention'
                  placeholder=''
                  value={messageInstructionsAtom?.intention || ''}
                  onChange={(e) =>
                    setMessageInstructionsKey('intention', e.target.value)
                  }
                  style={{borderRight: 'none'}}
                  className={!!intentionError ? 'invalidInput' : ''}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      e.target.blur()
                    }
                  }}
                />
              </FloatingLabel>
              <InputGroup.Text
                style={{borderLeft: 'none'}}
                className={!!intentionError ? 'invalidInput' : ''}
              >
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip id='intention-help-tooltip'>
                      What would you like to achieve with the message?
                      <br /> Example: Invite the prospect to a product demonstration.
                    </Tooltip>
                  }
                >
                  <span className='material-symbols-outlined color--s-dark'>
                    {'help'}
                  </span>
                </OverlayTrigger>
              </InputGroup.Text>
            </InputGroup>
          </InputWrapper>
        </Form.Group>

        <h6 style={{textAlign: 'center', paddingBottom: 0, marginBottom: 0}}>
          Your Message Preferences
        </h6>

        <InputGroup
          as={Row}
          style={{
            maxHeight: '25rem',
            overflowY: 'auto'
          }}
        >
          <DynamicInputGroup
            onChange={(newPreferences) =>
              setMessageInstructionsKey('preferences', newPreferences)
            }
            values={messageInstructionsAtom?.preferences}
            inputValidator={inputValidator}
          />
        </InputGroup>
      </Form>
    )
  }
)

const MessageForm = memo(
  ({
    enrichmentCompany,
    enrichmentCompanyFetchStatus,
    enrichmentCompanyStatus,
    enrichmentCompanyUrl,
    enrichmentCompanyUrlIsDebouncing,
    enrichmentPerson,
    enrichmentPersonFetchStatus,
    enrichmentPersonStatus,
    enrichmentPersonUrl,
    enrichmentPersonUrlIsDebouncing,
    inputMessages,
    inputMessagesAreCorrect,
    messageInstructionsAtom,
    messages,
    messagesFetchStatus,
    messageInstructions,
    messageInstructionsFetchStatus,
    setEnrichmentCompanyUrl,
    setEnrichmentPersonUrl,
    setInputMessages,
    setMessageInstructionsKey
  }) => {
    const [showMessageSignature, setShowMessageSignature] = useState(false)

    const [showGenerateMessageModal, setShowGenerateMessageModal] = useState(false)
    const [showMessageSignatureModal, setShowMessageSignatureModal] = useState(false)
    const [showMessagePreviewModal, setShowMessagePreviewModal] = useState(false)
    const [showMessageLoadModal, setShowMessageLoadModal] = useState(false)

    const [activeMessage, setActiveMessage] = useState(null)
    const [activeMessageIndex, setActiveMessageIndex] = useState(null)

    const initialMessages = useMemo(
      () => messages?.filter((m) => m.outreach_stage === 'initial_message') || [],
      [messages]
    )

    const followUpMessages = useMemo(
      () => messages?.filter((m) => m.outreach_stage === 'follow_up_message') || [],
      [messages]
    )

    const messageMap = useMemo(() => {
      const map = new Map()
      messages?.forEach((msg) => map.set(msg.message_id, msg))
      return map
    }, [messages])

    const personUrlIsLoading =
      enrichmentPersonUrlIsDebouncing || enrichmentPersonFetchStatus === 'fetching'
    const companyUrlIsLoading =
      enrichmentCompanyUrlIsDebouncing || enrichmentCompanyFetchStatus === 'fetching'

    const handleMessageChange = (index, newMessageId) => {
      setInputMessages((prevMessages) => {
        const updatedMessages = [...prevMessages]
        updatedMessages[index] = newMessageId
        return updatedMessages
      })
    }

    const handleRemoveFollowUp = (index) => {
      setInputMessages((prevMessages) => prevMessages.filter((_, i) => i !== index))
    }

    const handleAddFollowUp = () => {
      setInputMessages((prevMessages) => [...prevMessages, ''])
    }

    useEffect(() => {
      if (messageInstructionsAtom?.messageSignature?.length > 0) {
        setShowMessageSignature(true)
      }
    }, [messageInstructionsAtom, setShowMessageSignature])

    const TimeLineItem = ({message, index}) => {
      const isInitial = index === 0
      const label = isInitial ? 'Initial Message' : `Follow-Up Message ${index}`
      const showRemove = !isInitial
      const currentMessage = inputMessages[index]
      const showConnector = isInitial
        ? true
        : index + 1 < inputMessages.length ||
          (inputMessagesAreCorrect
            ? inputMessages.length < 4
            : inputMessages.length - index > 3)
      const greenConnector = index + 1 < inputMessages.length

      return (
        <TimelineItem key={`message-tl-${index}`}>
          <TimelineOppositeContent className='d-flex flex-column justify-content-start align-items-end'>
            <Form.Text
              muted
              className='d-text__font--heading'
              style={{fontSize: '1rem'}}
            >
              {label}
            </Form.Text>
            {isTruthy(currentMessage) && (
              <Form.Text
                muted
                className='d-text__font--heading clickable transition-fast t-color--accent'
                style={{fontSize: '0.833rem', textDecoration: 'underline'}}
                onClick={() => {
                  const message = messageMap?.get(inputMessages[index])
                  if (message) {
                    setActiveMessage(message)
                    setShowMessagePreviewModal(true)
                  }
                }}
              >
                Preview
              </Form.Text>
            )}
          </TimelineOppositeContent>

          <TimelineSeparator>
            <TimelineDot
              variant='outlined'
              className={isTruthy(currentMessage) ? 'border-color--success' : ''}
            />
            <Collapse in={showConnector}>
              <TimelineConnector
                style={{width: '1.5px'}}
                className={greenConnector ? 'background--success' : ''}
              />
            </Collapse>
          </TimelineSeparator>

          <TimelineContent className='d-flex justify-content-start align-items-start'>
            <Form.Label
              className='d-text__font--heading'
              style={{fontSize: '1rem'}}
            >
              <span
                className='color--accent clickable transition-fast t-underline--accent'
                onClick={() => {
                  setActiveMessageIndex(index)
                  setShowMessageLoadModal(true)
                }}
              >
                Load
              </span>
              <span
                style={{
                  marginRight: '0.5rem',
                  marginLeft: '0.5rem',
                  color: '#BDBDBD'
                }}
              >
                |
              </span>
              <span
                onClick={() => {
                  setActiveMessageIndex(index)
                  setShowGenerateMessageModal(true)
                }}
                className='color--accent clickable transition-fast t-underline--accent'
              >
                Generate
              </span>
              {showRemove && (
                <>
                  <span
                    style={{
                      marginRight: '0.5rem',
                      marginLeft: '0.5rem',
                      color: '#BDBDBD'
                    }}
                  >
                    |
                  </span>
                  <span
                    className='color--accent clickable transition-fast t-underline--accent'
                    onClick={() => {
                      handleRemoveFollowUp(index)
                    }}
                  >
                    Remove
                  </span>
                </>
              )}
            </Form.Label>
          </TimelineContent>
        </TimelineItem>
      )
    }

    return (
      <>
        {showGenerateMessageModal && (
          <GenerateMessageModal
            activeMessageIndex={activeMessageIndex}
            enrichmentCompany={enrichmentCompany}
            enrichmentPerson={enrichmentPerson}
            handleMessageChange={handleMessageChange}
            inputMessages={inputMessages}
            isFollowUpMessage={activeMessageIndex > 0}
            messageInstructions={messageInstructions}
            messageInstructionsFetchStatus={messageInstructionsFetchStatus}
            messages={messages}
            onHide={() => setShowGenerateMessageModal(!showGenerateMessageModal)}
            show={showGenerateMessageModal}
          />
        )}
        {showMessageLoadModal && (
          <LoadMessageModal
            activeMessageIndex={activeMessageIndex}
            messages={activeMessageIndex === 0 ? initialMessages : followUpMessages}
            messagesFetchStatus={messagesFetchStatus}
            handleMessageChange={handleMessageChange}
            show={showMessageLoadModal}
            onHide={() => setShowMessageLoadModal(!showMessageLoadModal)}
          />
        )}
        {showMessageSignatureModal && (
          <SignatureModal
            show={showMessageSignatureModal}
            onHide={() => setShowMessageSignatureModal(!showMessageSignatureModal)}
            setMessageInstructionsKey={setMessageInstructionsKey}
            messageInstructionsAtom={messageInstructionsAtom}
          />
        )}
        {showMessagePreviewModal && (
          <MessageModal
            show={showMessagePreviewModal}
            onHide={() => {
              setShowMessagePreviewModal(false)
              setActiveMessage(null)
            }}
            label={activeMessage?.label}
            emailSubject={activeMessage?.message_header || ''}
            emailBody={activeMessage?.message_body || ''}
            updatedAt={activeMessage?.updated_at || ''}
          />
        )}

        <Form className='d-form'>
          <Form.Group as={Row}>
            <InputGroup
              as={Col}
              style={{marginRight: '1rem'}}
            >
              <FloatingLabel label='Outreach Method'>
                <Form.Select
                  onChange={(e) =>
                    setMessageInstructionsKey('outreachMethod', e.target.value)
                  }
                  value={messageInstructionsAtom?.outreachMethod || ''}
                >
                  <option value='email'>Email</option>
                </Form.Select>
              </FloatingLabel>
            </InputGroup>

            <Col
              xs='auto'
              className='d-flex align-items-center'
            >
              <Form.Check
                type='switch'
                id='custom-switch'
                label='Custom Email Signature'
                reverse
                style={{marginRight: '1rem'}}
                checked={showMessageSignature}
                onChange={() => {
                  setShowMessageSignature(!showMessageSignature)
                  setMessageInstructionsKey('messageSignature', null)
                }}
              />
              <Collapse
                in={showMessageSignature}
                dimension='width'
              >
                <span
                  onClick={() =>
                    setShowMessageSignatureModal(!showMessageSignatureModal)
                  }
                  className='material-symbols-outlined clickable transition-fast t-color--accent'
                >
                  {'edit_square'}
                </span>
              </Collapse>
            </Col>
          </Form.Group>

          <hr style={{marginLeft: '3rem', marginRight: '3rem'}} />

          <Form.Group as={Row}>
            <Col
              xs={3}
              xl={2}
              style={{paddingRight: '1rem'}}
              className='d-flex flex-column justify-content-center'
            >
              <Form.Label as={Row}>Recipient</Form.Label>
              <Collapse in={!!enrichmentPerson}>
                <div>
                  <Form.Text
                    muted
                    as={Row}
                  >
                    {enrichmentPerson?.first_name}
                  </Form.Text>
                </div>
              </Collapse>
            </Col>

            <InputGroup as={Col}>
              <InputGroup.Text
                className={!isTruthy(enrichmentPersonUrl) ? 'invalidInput' : ''}
              >
                <LinkedinIcon
                  style={{
                    height: 'inherit',
                    width: '2rem',
                    fill: '#0a66c2'
                  }}
                />
              </InputGroup.Text>

              <FloatingLabel label={'Recipients Profile URL'}>
                <Form.Control
                  className={!isTruthy(enrichmentPersonUrl) ? 'invalidInput' : ''}
                  type='text'
                  value={enrichmentPersonUrl || ''}
                  onChange={(e) => setEnrichmentPersonUrl(e.target.value)}
                  name='recipient-profile'
                  placeholder='https://www.linkedin.com/in/charlieroos/'
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      e.target.blur()
                    }
                  }}
                />
              </FloatingLabel>
              {personUrlIsLoading && (
                <InputGroup.Text style={{borderLeft: 'none'}}>
                  <Spinner
                    size='sm'
                    animation='grow'
                  />
                </InputGroup.Text>
              )}
              {enrichmentPersonStatus === 'success' && !personUrlIsLoading && (
                <InputGroup.Text style={{borderLeft: 'none'}}>
                  <span className='material-symbols-outlined color--success'>
                    {'check'}
                  </span>
                </InputGroup.Text>
              )}
              {enrichmentPersonStatus === 'error' && !personUrlIsLoading && (
                <InputGroup.Text style={{borderLeft: 'none'}}>
                  <span
                    className='material-symbols-outlined'
                    style={{color: 'red'}}
                  >
                    {'close'}
                  </span>
                </InputGroup.Text>
              )}
            </InputGroup>
          </Form.Group>

          <Collapse in={enrichmentPersonStatus === 'success' && !personUrlIsLoading}>
            <div>
              <Form.Group as={Row}>
                <Col
                  xs={3}
                  xl={2}
                  style={{paddingRight: '1rem'}}
                  className='d-flex flex-column justify-content-center'
                >
                  <Form.Label as={Row}>Employer</Form.Label>
                  <Collapse in={!!enrichmentCompany}>
                    <div>
                      <Form.Text
                        muted
                        as={Row}
                      >
                        {enrichmentCompany?.name}
                      </Form.Text>
                    </div>
                  </Collapse>
                </Col>

                <InputGroup as={Col}>
                  <InputGroup.Text
                    className={!isTruthy(enrichmentCompanyUrl) ? 'invalidInput' : ''}
                  >
                    <LinkedinIcon
                      style={{
                        height: 'inherit',
                        width: '2rem',
                        fill: '#0a66c2'
                      }}
                    />
                  </InputGroup.Text>

                  <FloatingLabel label={'Employers Profile URL'}>
                    <Form.Control
                      className={!isTruthy(enrichmentCompanyUrl) ? 'invalidInput' : ''}
                      type='text'
                      value={enrichmentCompanyUrl || ''}
                      onChange={(e) => setEnrichmentCompanyUrl(e.target.value)}
                      name='employer-profile'
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          e.target.blur()
                        }
                      }}
                      placeholder='https://www.linkedin.com/in/pitchr/'
                    />
                  </FloatingLabel>

                  {companyUrlIsLoading && (
                    <InputGroup.Text style={{borderLeft: 'none'}}>
                      <Spinner
                        size='sm'
                        animation='grow'
                      />
                    </InputGroup.Text>
                  )}
                  {enrichmentCompanyStatus === 'success' && !companyUrlIsLoading && (
                    <InputGroup.Text style={{borderLeft: 'none'}}>
                      <span className='material-symbols-outlined color--success'>
                        {'check'}
                      </span>
                    </InputGroup.Text>
                  )}
                  {enrichmentCompanyStatus === 'error' && !companyUrlIsLoading && (
                    <InputGroup.Text style={{borderLeft: 'none'}}>
                      <span
                        className='material-symbols-outlined'
                        style={{color: 'red'}}
                      >
                        {'close'}
                      </span>
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </Form.Group>
            </div>
          </Collapse>

          <hr style={{marginLeft: '3rem', marginRight: '3rem'}} />

          <Form.Group as={Row}>
            <InputGroup as={Col}>
              <FloatingLabel label='Outreach Stage'>
                <Form.Select
                  onChange={(e) => {
                    setMessageInstructionsKey('outreachStage', e.target.value)
                  }}
                  value={messageInstructionsAtom?.outreachStage}
                >
                  <option value='initial_message'>Initial Message</option>
                  <option value='follow_up_message'>Follow-Up Message</option>
                </Form.Select>
              </FloatingLabel>
            </InputGroup>
          </Form.Group>

          <Collapse
            in={
              messageInstructionsAtom?.outreachStage === 'follow_up_message' &&
              isTruthy(enrichmentPersonUrl) &&
              isTruthy(enrichmentCompanyUrl)
            }
          >
            <div>
              <Timeline>
                <TimeLineItem
                  message={inputMessages?.[0]}
                  index={0}
                  key={`timeline-item-0`}
                />
                {inputMessages?.slice(1)?.map((message, index) => (
                  <TimeLineItem
                    message={inputMessages?.[index + 1]}
                    index={index + 1}
                    key={`timeline-item-${index + 1}`}
                  />
                ))}

                <Collapse
                  in={
                    (inputMessages?.length < 4 && inputMessagesAreCorrect) ||
                    inputMessages?.length === 1
                  }
                >
                  <div>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot
                          variant='outlined'
                          style={{
                            borderColor: '#BDBDBD',
                            marginTop: '0.5rem'
                          }}
                        >
                          <span
                            className='material-symbols-outlined'
                            style={{
                              color: '#BDBDBD',
                              fontSize: '1rem'
                            }}
                          >
                            {'add'}
                          </span>
                        </TimelineDot>
                      </TimelineSeparator>
                      <TimelineContent className='d-flex justify-content-start'>
                        <Form.Label
                          muted
                          className='d-text__font--heading color--accent clickable transition-fast t-underline--accent'
                          onClick={handleAddFollowUp}
                          style={{
                            fontSize: '1rem',
                            lineHeight: '1rem',
                            marginTop: '0.5rem'
                          }}
                        >
                          Add Follow-Up
                        </Form.Label>
                      </TimelineContent>
                    </TimelineItem>
                  </div>
                </Collapse>
              </Timeline>
            </div>
          </Collapse>
        </Form>
      </>
    )
  }
)

export const MessageInstructionsInput = memo(
  ({
    messagesFetchStatus,
    allInputInformationIsCorrect,
    companyNameError,
    enrichmentCompany,
    enrichmentCompanyFetchStatus,
    enrichmentCompanyStatus,
    enrichmentCompanyUrl,
    enrichmentCompanyUrlIsDebouncing,
    enrichmentPerson,
    enrichmentPersonFetchStatus,
    enrichmentPersonStatus,
    enrichmentPersonUrl,
    enrichmentPersonUrlIsDebouncing,
    firstNameError,
    handleLoadMessageInstructions,
    handlePatchMessageInstructions,
    handlePostMessageInstructions,
    inputMessagesAreCorrect,
    inputMessages,
    inputValidator,
    intentionError,
    lastNameError,
    messageInformationInputIsCorrect,
    messageInstructions,
    messageInstructionsAtom,
    messageInstructionsFetchStatus,
    messages,
    patchMessageInstructionsStatus,
    postMessageInstructionsStatus,
    preferencesInformationInputIsCorrect,
    profileInformationInputIsCorrect,
    setEnrichmentCompanyUrl,
    setEnrichmentPersonUrl,
    setInputMessages,
    setMessageInstructionsKey,
    supportedLanguages,
    supportedLanguagesFetchStatus,
    titleError,
    userProfile,
    userProfileFetchStatus,
    valuePropositionError
  }) => {
    const [activeStep, setActiveStep] = useState(0)
    const [showInput, setShowInput] = useState(true)
    const [openSaveDialog, setOpenSaveDialog] = useState(false)
    const [openLoadDialog, setOpenLoadDialog] = useState(false)

    const steps = [
      {
        header: 'Profile',
        icon: 'person',
        informationIsCorrect: profileInformationInputIsCorrect
      },
      {
        header: 'Message',
        icon: 'send',
        informationIsCorrect: messageInformationInputIsCorrect
      },
      {
        header: 'Preferences',
        icon: 'tune',
        informationIsCorrect: preferencesInformationInputIsCorrect
      }
    ]
    return (
      <>
        {openLoadDialog && (
          <LoadMessageInstructionsDialog
            handleLoadMessageInstructions={handleLoadMessageInstructions}
            messageInstructions={messageInstructions}
            messageInstructionsFetchStatus={messageInstructionsFetchStatus}
            onHide={() => setOpenLoadDialog(!openLoadDialog)}
            open={openLoadDialog}
          />
        )}
        {openSaveDialog && (
          <SaveMessageInstructionsDialog
            allInputInformationIsCorrect={allInputInformationIsCorrect}
            handlePatchMessageInstructions={handlePatchMessageInstructions}
            handlePostMessageInstructions={handlePostMessageInstructions}
            messageInstructions={messageInstructions}
            messageInstructionsAtom={messageInstructionsAtom}
            messageInstructionsFetchStatus={messageInstructionsFetchStatus}
            onHide={() => setOpenSaveDialog(!openSaveDialog)}
            open={openSaveDialog}
            patchMessageInstructionsStatus={patchMessageInstructionsStatus}
            postMessageInstructionsStatus={postMessageInstructionsStatus}
            setMessageInstructionsKey={setMessageInstructionsKey}
          />
        )}
        <Container fluid>
          <div className='d-flex justify-content-between align-items-center'>
            <h5
              className='d-text__font--heading color--p-dark d-flex align-items-center transition-fast clickable t-color--accent'
              onClick={() => setShowInput(!showInput)}
            >
              Instructions
              <span
                className='material-symbols-outlined'
                style={{
                  fontSize: '2rem',
                  transform: showInput ? 'rotate(0deg)' : 'rotate(180deg)',
                  transition: 'transform 0.3s ease'
                }}
              >
                {'keyboard_arrow_down'}
              </span>
            </h5>
            <Dropdown drop='start'>
              <Dropdown.Toggle
                variant='none'
                bsPrefix='dropdownToggle'
                style={{border: 'none', padding: 0}}
                children={
                  <span
                    className='material-symbols-outlined transition-fast clickable t-scale--small t-color--accent'
                    style={{fontSize: '2rem', color: 'black'}}
                  >
                    {'more_horiz'}
                  </span>
                }
              />
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setOpenLoadDialog(!openLoadDialog)}>
                  Load Instructions
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setOpenSaveDialog(!openSaveDialog)}>
                  Save Instructions
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <hr style={{marginTop: 0}} />
          <Collapse in={showInput}>
            <div>
              <Row>
                <Col xs='auto'>
                  <WorkflowStepper
                    steps={steps}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                  />
                </Col>

                <Col>
                  <Collapse
                    in={activeStep === 0}
                    mountOnEnter
                    unmountOnExit
                  >
                    <div>
                      <ProfileInformationForm
                        companyNameError={companyNameError}
                        firstNameError={firstNameError}
                        lastNameError={lastNameError}
                        messageInstructionsAtom={messageInstructionsAtom}
                        setMessageInstructionsKey={setMessageInstructionsKey}
                        supportedLanguages={supportedLanguages}
                        supportedLanguagesFetchStatus={supportedLanguagesFetchStatus}
                        titleError={titleError}
                        userProfile={userProfile}
                        userProfileFetchStatus={userProfileFetchStatus}
                        valuePropositionError={valuePropositionError}
                      />
                    </div>
                  </Collapse>
                  <Collapse
                    in={activeStep === 1}
                    mountOnEnter
                    unmountOnExit
                  >
                    <div>
                      <MessageForm
                        messageInstructionsAtom={messageInstructionsAtom}
                        setMessageInstructionsKey={setMessageInstructionsKey}
                        enrichmentCompany={enrichmentCompany}
                        enrichmentCompanyFetchStatus={enrichmentCompanyFetchStatus}
                        enrichmentCompanyStatus={enrichmentCompanyStatus}
                        enrichmentCompanyUrl={enrichmentCompanyUrl}
                        enrichmentCompanyUrlIsDebouncing={
                          enrichmentCompanyUrlIsDebouncing
                        }
                        enrichmentPerson={enrichmentPerson}
                        enrichmentPersonFetchStatus={enrichmentPersonFetchStatus}
                        enrichmentPersonStatus={enrichmentPersonStatus}
                        enrichmentPersonUrl={enrichmentPersonUrl}
                        enrichmentPersonUrlIsDebouncing={
                          enrichmentPersonUrlIsDebouncing
                        }
                        inputMessages={inputMessages}
                        inputMessagesAreCorrect={inputMessagesAreCorrect}
                        messages={messages}
                        messagesFetchStatus={messagesFetchStatus}
                        setEnrichmentCompanyUrl={setEnrichmentCompanyUrl}
                        setEnrichmentPersonUrl={setEnrichmentPersonUrl}
                        messageInstructions={messageInstructions}
                        messageInstructionsFetchStatus={messageInstructionsFetchStatus}
                        setInputMessages={setInputMessages}
                      />
                    </div>
                  </Collapse>
                  <Collapse
                    in={activeStep === 2}
                    mountOnEnter
                    unmountOnExit
                  >
                    <div>
                      <PreferencesForm
                        messageInstructionsAtom={messageInstructionsAtom}
                        setMessageInstructionsKey={setMessageInstructionsKey}
                        intentionError={intentionError}
                        inputValidator={inputValidator}
                      />
                    </div>
                  </Collapse>
                </Col>
              </Row>
            </div>
          </Collapse>
        </Container>
      </>
    )
  }
)
