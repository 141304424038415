import {Button as BootstrapButton, Tooltip, OverlayTrigger} from 'react-bootstrap'
import {Spinner} from '../atoms/Spinner.js'

export const Button = ({
  children,
  onClick,
  className = 'd-button',
  disabledText = null,
  disabled = false,
  isLoading = false,
  style = {},
  type = 'button',
  variant = 'outline-primary'
}) => {
  const buttonDisabled = disabled || isLoading

  const tooltipOverlay = <Tooltip id='button-tooltip'>{disabledText}</Tooltip>

  const buttonContent = (
    <BootstrapButton
      disabled={buttonDisabled}
      onClick={buttonDisabled ? undefined : onClick}
      variant={variant}
      style={{pointerEvents: 'all', ...style}}
      type={type}
      className={className}
    >
      {children}
      {isLoading && (
        <>
          <span style={{marginLeft: '0.5rem'}}></span>
          <Spinner size='sm' />
        </>
      )}
    </BootstrapButton>
  )

  if (buttonDisabled && disabledText) {
    return (
      <OverlayTrigger
        overlay={tooltipOverlay}
        placement='top'
      >
        {buttonContent}
      </OverlayTrigger>
    )
  } else {
    return buttonContent
  }
}
