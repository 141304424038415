import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query'
import * as agentApi from '../../services/agentApi.js'
import {errorToast, successToast} from '../../components/atoms/Toasts.js'

export const useGetSupportedLanguages = () => {
  return useQuery({
    queryKey: ['messageSupportedLanguages'],
    queryFn: () => agentApi.getSupportedLanguages(),
    staleTime: 7 * 24 * 60 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    meta: {
      errorMessage: 'Fetching Supported Languages Failed.\nPlease try again later.'
    }
  })
}
export const useGetMessages = ({params} = {}) => {
  return useQuery({
    queryKey: ['messages', params],
    queryFn: () => agentApi.getMessages({params}),
    staleTime: 8 * 60 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    meta: {
      errorMessage: 'Fetching Messages Failed.\nPlease try again later.'
    }
  })
}
export const usePostMessage = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({payload}) => agentApi.postMessage({payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messages']})
      successToast('Message Saved!')
    },
    onError: () => {
      errorToast('Saving Message Failed.\nPlease try again later.')
    }
  })
}
export const usePostLeadsRank = ({payload, enabled = true}) => {
  return useQuery({
    queryKey: ['leadsRank', payload],
    queryFn: () => agentApi.postLeadsRank({payload}),
    enabled: !!enabled,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 6 * 24 * 60 * 60 * 1000,
    meta: {
      errorMessage: 'AI Employee Ranking Failed.\nPlease try again later.'
    }
  })
}
export const usePatchMessage = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params, payload}) => agentApi.patchMessage({params, payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messages']})
      successToast('Message Updated!')
    },
    onError: () => {
      errorToast('Message Update Failed.\nPlease try again later.')
    }
  })
}
export const useDeleteMessage = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params}) => agentApi.deleteMessage({params}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messages']})
      successToast('Message Deleted!')
    },
    onError: () => {
      errorToast('Message Deletion Failed.\nPlease try again later.')
    }
  })
}
export const usePostMessageGenerate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({payload}) => agentApi.postMessageGenerate({payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['userCredit']})
      successToast('Message Generated!')
    },
    onError: () => {
      errorToast('Generating Message Failed.\nPlease try again later.')
    }
  })
}
export const useGetMessageInstructions = ({params} = {}) => {
  return useQuery({
    queryKey: ['messageInstructions', params],
    queryFn: () => agentApi.getMessageInstructions({params}),
    staleTime: 8 * 60 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
    meta: {
      errorMessage: 'Fetching Message Instructions Failed.\nPlease try again later.'
    }
  })
}
export const usePostMessageInstructions = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({payload}) => agentApi.postMessageInstructions({payload}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messageInstructions']})
      successToast('Message Instructions Saved!')
    },
    onError: () => {
      errorToast('Saving Message Instructions Failed.\nPlease try again later.')
    }
  })
}
export const usePatchMessageInstructions = ({
  showToast = true,
  invalidateQuery = true
} = {}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params, payload}) =>
      agentApi.patchMessageInstructions({params, payload}),
    onSuccess: () => {
      if (invalidateQuery) {
        queryClient.invalidateQueries({queryKey: ['messageInstructions']})
      }
      if (showToast) {
        successToast('Message Instructions Updated!')
      }
    },
    onError: () => {
      errorToast('Updating Message Instructions Failed.\nPlease try again later.')
    }
  })
}
export const useDeleteMessageInstructions = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({params}) => agentApi.deleteMessageInstructions({params}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messageInstructions']})
      successToast('Message Instructions Deleted!')
    },
    onError: () => {
      errorToast('Deleting Message Instructions Failed.\nPlease try again later.')
    }
  })
}
